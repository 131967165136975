import {
	request
} from './request'

//获取首页信息
export function getHomeInfo(data) {
	return request({
		url: '/api/index',
		method: "post",
		data
	})
}
//获取菜单信息
export function getMenuInfo(data) {
	return request({
		url: '/api/menu',
		method: "post",
		data
	})
}
//获取foot信息
export function getFootInfo(data) {
	return request({
		url: '/api/foot',
		method: "post",
		data
	})
}
//获取goods信息
export function getGoodsInfo(data) {
	return request({
		url: '/api/goods',
		method: "post",
		data: data
	})
}
//获取解决方案信息
export function getProgrammeInfo(data) {
	return request({
		url: '/api/programme',
		method: "post",
		data
	})
}

// 集团案例场景和品牌列表
export function getCaseInfo(data) {
	return request({
		url: '/api/case_cate',
		method: "post",
		data
	})
}
//获取集团案例列表
export function getCaseList(data) {
	return request({
		url: '/api/case_group_list',
		method: "post",
		data
	})
}
//获取集团案例详情信息
export function getGroupInfoDetail(data) {
	return request({
		url: '/api/group_case',
		method: "post",
		data
	})
}
//获取品牌案例详情信息
export function getBrandDetail(data) {
	return request({
		url: '/api/brand_case',
		method: "post",
		data
	})
}
//获取资讯列表信息
export function getNews(data) {
	return request({
		url: '/api/news',
		method: "post",
		data
	})
}
//获取资讯详情信息
export function getNewsDetail(data) {
	return request({
		url: '/api/news_detail',
		method: "post",
		data
	})
}
//获取关于我们信息
export function getAboutUs(data) {
	return request({
		url: '/api/about',
		method: "post",
		data
	})
}
//提交预约
export function reservation(data) {
	return request({
		url: '/api/reservation',
		method: "post",
		data
	})
}
//获取验证码id
export function getUniqid(data) {
	return request({
		url: '/api/uniqid',
		method: "post",
		data
	})
}
//获取验证码图片
export function getUniqidPho(data) {
	return request({
		url: '/api/uniqid',
		method: "post",
		data
	})
}

