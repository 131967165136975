<template>
  <div @click="toCustomer()" class="swiper-container">
    <swiper
      class="swiper-loopcontainer"
      :options="swiperOption"
      ref="loopSwiper"
      @mouseenter.native="enter"
      @mouseleave.native="leave"
    >
      <swiper-slide
        class="swiper-wrapper"
        v-for="(item, index) in imageList"
        :key="index"
      >
        <div class="swiper_item">
          <img v-if="item.image" :src="$commonJs.formatImgSrc(item.image)" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { BASE_URL } from "@/config/index.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "swiper-example-loop-group",
  title: "Loop mode with multiple slides per group",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    imageList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  created() {},
  data() {
    return {
      BASE_URL: BASE_URL,
      swiperOption1: {
        // autoplay: true, // 自动轮播
        autoplay: {
          disableOnInteraction: false,
          delay: 0,
          stopOnLastSlide: false,
        },
        speed: 2000, // 轮播速度
        slidesPerView: 6,
        spaceBetween: 0,
        // slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next2",
          prevEl: ".swiper-button-prev2",
        },
      },
      swiperOption: {
        preventClicksPropagation: true,
        speed: 3000, //匀速时间
        freeMode: true,
        loop: true,
        slidesOffsetBefore: 0, //左边偏移量
        autoplay: {
          delay: 0, //停顿时间
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        slidesPerView: 9,
        spaceBetween: 1, //间隔
        loopFillGroupWithBlank: true,
        normalizeSlideIndex: true,
        allowTouchMove: false, //不允许触摸滑动
        centeredSlides: true, //当slide的总数小于slidesPerView时，slide居中
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next2",
          prevEl: ".swiper-button-prev2",
        },
      },
    };
  },
  computed: {
    swiper() {
      if (this.$refs.loopSwiper) {
        return this.$refs.loopSwiper.$swiper;
      } else {
        return "";
      }
    },
  },
  methods: {
    toCustomer(item, index) {
      this.$router.push("/customers");
    },
    enter() {
      // if (this.loopSwiperList.length <= 6) {
      //   return false;
      // }
      console.log("enter");
      // return;
      let that = this;
      if (that.$refs.loopSwiper) {
        this.nextTransForm = document
          .getElementsByClassName("swiper-loopcontainer")[0]
          .getElementsByClassName("swiper-wrapper")[0].style.transform;
        // 轮播图原本应移动到的位置
        let nextTransPosition =
          -1 *
          parseInt(
            document
              .getElementsByClassName("swiper-loopcontainer")[0]
              .getElementsByClassName("swiper-wrapper")[0]
              .style.transform.split("translate3d(")[1]
              .split("px")[0]
          );

        // 鼠标悬浮时时轮播图位置
        let nowTransPosition =
          -1 *
          parseInt(
            window
              .getComputedStyle(
                document
                  .getElementsByClassName("swiper-loopcontainer")[0]
                  .getElementsByClassName("swiper-wrapper")[0],
                false
              )
              ["transform"].split("1, ")[2]
              .split(",")[0]
          );
        // 存放鼠标悬浮时轮播图的真实transform属性（非行内属性）
        let nowTransForm = window.getComputedStyle(
          document
            .getElementsByClassName("swiper-loopcontainer")[0]
            .getElementsByClassName("swiper-wrapper")[0],
          false
        )["transform"];
        // 计算轮播图从暂停位置移动到原本应到的位置所用的时间（370是我自定义的每个slide的宽度）
        this.nextTime = 5500 * ((nextTransPosition - nowTransPosition) / 370);
        // 改变行内transform属性
        document
          .getElementsByClassName("swiper-loopcontainer")[0]
          .getElementsByClassName("swiper-wrapper")[0].style.transform =
          nowTransForm;
        // 不写也没关系
        document
          .getElementsByClassName("swiper-loopcontainer")[0]
          .getElementsByClassName(
            "swiper-wrapper"
          )[0].style.transitionDuration = "0ms";
        // this.swiper.autoplay.stop();
        that.$refs.loopSwiper.$swiper.autoplay.stop();
      }
    },
    leave() {
      // if (this.loopSwiperList.length <= 6) {
      //   return false;
      // }
      console.log("leave");
      // return;
      let that = this;
      if (that.$refs.loopSwiper) {
        // 恢复原样
        document
          .getElementsByClassName("swiper-loopcontainer")[0]
          .getElementsByClassName("swiper-wrapper")[0].style.transform =
          this.nextTransForm;
        document
          .getElementsByClassName("swiper-loopcontainer")[0]
          .getElementsByClassName(
            "swiper-wrapper"
          )[0].style.transitionDuration = this.nextTime + "ms";
        this.swiper.autoplay.start();
        that.$refs.loopSwiper.$swiper.autoplay.start();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: linear;
  /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
  cursor: pointer;
}

img {
  object-fit: cover;
  width: 88px;
  height: 56px;
  vertical-align: middle;
}
.swiper-loopcontainer {
  box-sizing: border-box;
  position: relative;
  .swiper_item {
    display: flex;
    justify-content: center;
    //border-right: 1px solid #bbbfc9;
  }
}
</style>