<template>
  <div @click="cardClick" class="dialog_style">
    <!-- 弹窗fullscreen -->
    <div class="dialog_content">
      <p class="dialog_content_title">预约体验</p>
      <p class="dialog_content_smalltitle">Welcome to register!</p>
      <el-form
        class="form_style"
        ref="dialogForm"
        :model="localDialogData"
        :rules="dialogRules"
        label-width="78px"
      >
        <template v-for="item in formItems">
          <!-- :prop="item.prop" -->
          <div :key="item.prop" style="" class="form_style_row">
            <p class="form_style_row_dot">*</p>
            <el-form-item style="flex: 1" :label="item.label">
              <div
                class="form_input_row"
                :class="item.type == 'select' ? 'form_input_row_sel' : ''"
                @click.stop.prevent="openCloseSel(item)"
              >
                <template v-if="item.type == 'select'">
                  <div class="sel_item_outer">
                    <template v-if="afterSelList.length > 0">
                      <div
                        v-for="(item, index) in afterSelList"
                        :key="index"
                        class="sel_item"
                        @click.stop.prevent="delItem(item)"
                      >
                        <span>{{ item.title }}</span>
                        <img src="../assets/img/guanbilv.png" alt="" />
                      </div>
                    </template>
                    <span v-else class="place_span">请选择产品</span>
                    <img
                      :class="showSel ? 'form_input_row_arrow_rotate' : ''"
                      class="form_input_row_arrow"
                      src="../assets/img/xiajiantou.png"
                      alt=""
                    />
                    <div
                      v-if="showSel && info.goods"
                      class="form_sel_box"
                      :class="showSel ? 'form_sel_box_show' : ''"
                    >
                      <template v-for="(item, index) in productList">
                        <div
                          @click.stop.prevent="selClick(item, index)"
                          :key="index"
                          class="form_sel_box_item"
                          :class="item.active ? 'form_sel_box_item_active' : ''"
                        >
                          <span>{{ item.title }}</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
                <el-input
                  v-else
                  class="el-input__inner"
                  v-model="form[item.prop]"
                  :placeholder="item.placeholder"
                  :maxlength="item.prop == 'mobile' ? 11 : 100"
                  clearable
                />
              </div>
            </el-form-item>
          </div>
        </template>
        <!--prop="captcha"  -->
        <div class="form_style_row">
          <p class="form_style_row_dot">*</p>
          <el-form-item style="flex: 1" key="captcha" label="验证码">
            <div class="code_box" style="">
              <el-input
                class="inputStyle"
                maxlength="4"
                :placeholder="'请输入验证码'"
                v-model="form.captcha"
                clearable=""
              />
              <div
                class="code_img"
                @click.stop.prevent="refreshCode"
                style="margin-left: 20px"
              >
                <!-- <Identify :identifyCode="identifyCode"></Identify> -->
                <img :src="codeUrl" alt="" />
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <button
          v-loading="btnLoading"
          @click.stop.prevent="confirmReserve"
          class="dialog_btn common_btn_style"
        >
          预约
        </button>
      </div>
    </div>
    <div class="success">
      <el-dialog
        :visible.sync="successVisible"
        lock-scroll
        width="460px"
        top="30vh"
        :close-on-click-modal="false"
        @close="successClose"
        :append-to-body="true"
        :show-close="false"
      >
        <div class="success_style">
          <div class="success_style_img">
            <img src="../assets/img/daduihao.png" alt="" />
          </div>
          <p>恭喜您！预约成功</p>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getMenuInfo,
  reservation,
  getUniqid,
  getUniqidPho,
} from "@/network/public";
import { BASE_URL } from "@/config/index";

export default {
  data() {
    return {
      BASE_URL: BASE_URL,
      btnLoading: false,
      afterSelList: [],
      identifyCode: "",
      identifyCodes: "1234567890",
      productList: [],
      info: {},
      successVisible: false,
      showSel: false,
      form: {
        name: "",
        position: "",
        enterprise_name: "",
        mobile: "",
        email: "",
        goods: "",
        uniqid: "",
      },
      localDialogVisible: false,
      localDialogData: [],
      tempOptions: [],
      dialogRules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            message: "请输入职位",
            trigger: "blur",
          },
        ],
        enterprise_name: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入工作邮箱",
            trigger: "blur",
          },
        ],
        goods: [
          {
            required: true,
            message: "请选择产品",
            trigger: "blur",
          },
        ],
        captcha: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      formItems: [
        {
          prop: "enterprise_name",
          label: "企业名称",
          type: "input",
          placeholder: "请输入企业名称",
        },
        {
          prop: "name",
          label: "姓  名",
          type: "input",
          placeholder: "请输入姓名",
        },
        {
          prop: "position",
          label: "职 位",
          type: "input",
          placeholder: "请输入职位",
        },
        {
          prop: "mobile",
          label: "手机号码",
          type: "input",
          placeholder: "请输入手机号码",
        },
        {
          prop: "email",
          label: "工作邮箱",
          type: "input",
          placeholder: "请输入工作邮箱",
        },
        {
          prop: "goods",
          label: "了解产品",
          type: "select",
          placeholder: "请选择产品",
        },
      ],
      codeUrl: "",
    };
  },
  props: {
    // 弹窗显示
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    // 弹窗数据
    dialogData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    dialogVisible: {
      handler(newValue, oldValue) {
        this.localDialogVisible = newValue;
        // 初始化验证码
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
      },
      deep: true,
      immediate: true,
    },
    dialogData: {
      handler(newValue, oldValue) {
        this.localDialogData = newValue;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {
    // 初始化验证码
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    this.getInfo();
    this.getUniqid();

    var oo = document.createElement("div");
    var ii = document.createElement("div");
    ii.style.height = "1px";
    oo.appendChild(ii);
    oo.style.visibility = "hidden";

    document.body.appendChild(oo);
    var wL = ii.clientWidth;
    var scrollbarWidth;
    oo.style.overflowY = "scroll";

    scrollbarWidth = wL - ii.clientWidth;

    console.log("scrollbar width: " + scrollbarWidth);
  },
  methods: {
    cardClick() {
      if (this.showSel) {
        this.showSel = false;
      }
    },
    getUniqid() {
      getUniqid()
        .then((res) => {
          if (res.status == 200) {
            this.codeUrl = this.BASE_URL + "/api/get_captcha/" + res.data.code;
            this.form.uniqid = res.data.code;
          }
        })
        .catch((e) => {});
    },
    getInfo() {
      getMenuInfo()
        .then((res) => {
          if (res.status == 200) {
            this.info = res.data;
            let param = {};
            this.productList = [];
            this.info.goods.list.forEach((item, index) => {
              param = {};
              param = {
                ...item,
              };
              param.active = false;
              this.productList.push(item);
            });
          }
        })
        .catch((e) => {});
    },
    delItem(item) {
      this.productList.forEach((proItem) => {
        if (item.id == proItem.id) {
          this.$set(proItem, "active", false);
        }
      });
      this.generateStr();
    },
    selClick(item, index) {
      this.$set(
        this.productList[index],
        "active",
        !this.productList[index].active
      );
      this.generateStr();
    },
    generateStr() {
      this.afterSelList = [];
      let str = "";
      this.productList.forEach((proItem) => {
        if (proItem.active) {
          this.afterSelList.push(proItem);
          if (str) {
            str = str + "," + proItem.title;
          } else {
            str = proItem.title;
          }
        }
      });
      this.form.goods = str;
    },
    //刷新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      this.getUniqid();
    },
    //生成验证上的随机数，验证码中的数从identifyCodes中取，
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    //生成随机数,这里是生成
    //Math.random()方法返回大于等于0小于1的一个随机数
    //随机数 = Math.floor(Math.random() * 可能的总数 + 第一个可能的值)
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    showSuccess() {
      this.successVisible = true;
      setTimeout(() => {
        this.successVisible = false;
      }, 3000);
    },
    successClose() {},
    confirmReserve() {
      if (this.btnLoading) {
        return false;
      }
      if (!this.form.enterprise_name) {
        this.$message.info("请输入企业名称！");
        return false;
      }
      if (!this.form.name) {
        this.$message.info("请输入姓名！");
        return false;
      }
      if (!this.form.position) {
        this.$message.info("请输入职位！");
        return false;
      }
      if (!this.form.mobile) {
        this.$message.info("请输入手机号码！");
        return false;
      } else {
        let isValid = this.$commonJs.isValidChinesePhoneNumber(
          this.form.mobile
        );
        if (!isValid) {
          this.$message.info("请输入有效的手机号码！");
          return false;
        }
      }
      if (!this.form.email) {
        this.$message.info("请输入邮箱！");
        return false;
      }
      if (!this.form.goods) {
        this.$message.info("请选择产品！");
        return false;
      }
      if (!this.form.name) {
        this.$message.info("请输入姓名！");
        return false;
      }
      if (!this.form.captcha) {
        this.$message.info("请输入验证码！");
        return false;
      }
      // else {
      //   if (this.form.captcha != this.identifyCode) {
      //     this.$message.info("验证码不正确！");
      //     this.refreshCode();
      //     return false;
      //   }
      // }
      this.btnLoading = true;
      reservation(this.form)
        .then((res) => {
          if (res.status == 200) {
            console.log(res);
            this.localDialogVisible = false;
            this.form = {
              name: "",
              position: "",
              enterprise_name: "",
              mobile: "",
              email: "",
              goods: "",
            };
            this.productList.forEach((proItem) => {
              this.$set(proItem, "active", false);
            });
            this.generateStr();
            this.showSuccess();
          } else {
            this.$message.error(res.msg);
            this.getUniqid();
          }
          this.btnLoading = false;
        })
        .catch((e) => {
          this.btnLoading = false;
        });
    },
    openCloseSel(item) {
      console.log("openCloseSel");
      if (item.type == "select") {
        this.showSel = !this.showSel;
      }
    },

    confirm() {
      this.$refs["dialogForm"].validate((valid) => {
        if (valid) {
          this.$emit("confirm");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handlerCancel() {
      this.$emit("handlerCancel");
    },
    clearValidate() {
      this.$refs["dialogForm"].clearValidate();
    },
    dialogDataChange(data, prop) {
      // console.log(data, prop)
      this.$set(this.localDialogData, prop, data);
      this.$emit("dialogDataChange", this.localDialogData);
    },
    // 将数组转换为1,2形式
    handleCheckedChange(value, prop) {
      let str = [...value].join(",");
      this.dialogDataChange(str, prop);
    },
    // 将1,2形式转换为数组
    convertStringToArray(str) {
      if (!str) {
        return [];
      }
      return str.split(",").map(Number);
    },
  },
};
</script>
<style lang="scss" scoped>
// .dialogStyle {
//   /* 滚动条样式优化 */
//   ::-webkit-scrollbar {
//     width: 0px;
//     height: 0px;
//     background: #cccccc;
//     border-radius: 4px;
//   }

//   .dialogInnerStyle {
//     max-height: 60vh;
//     overflow-y: scroll;
//     padding: 0 20px;
//   }
// }

// ::v-deep .el-form-item__content {
//   line-height: 20px !important;
// }

// ::v-deep .el-checkbox-group {
//   line-height: 40px !important;
// }

.dialog_style {
  padding: 0 auto;
  padding-top: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .dialog_content {
    padding: 0 60px;
    padding-bottom: 30px;
    width: 40vw;

    &_title {
      font-weight: 700;
      font-size: 28px;
      color: #222222;
      line-height: 28px;
    }
    &_smalltitle {
      font-weight: 400;
      font-size: 18px;
      color: #8d9296;
      line-height: 18px;
      margin-top: 19px;
    }
  }
  .form_style {
    margin-top: 52px;

    .form_style_row {
      display: flex;
      white-space: nowrap;
      .form_style_row_dot {
        color: #f56c6c;
        line-height: 50px;
        margin-right: 4px;
      }
    }
    .el-form-item__label {
      font-weight: 400;
      font-size: 16px;
      color: rgba(34, 34, 34, 1);
      line-height: 46px;
      text-align-last: justify;
      // text-align: left;
      white-space: nowrap;
      margin-right: 6px;
    }
    .el-form-item.is-required:not(.is-no-asterisk)
      .el-form-item__label-wrap
      > .el-form-item__label:before,
    .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      display: none;
    }
    .form_input_row {
      position: relative;
      min-height: 46px;
      display: flex;
      align-items: center;
      background: #f8f8f8;
      border-radius: 23px 23px 23px 23px;
      padding: 0;
      padding-right: 10px;

      .form_input_row_arrow {
        position: absolute;
        right: 24px;
        top: 21px;
        min-width: 9.79px;
        height: 4.27px;
        transition: all 0.3s ease-in-out;
      }
      .form_input_row_arrow_rotate {
        transform: rotate(180deg);
      }
      .el-input__inner {
        background: #f8f8f8;
        border: none;
        border-radius: 23px 23px 23px 23px;
        padding-left: 12px;
      }

      .sel_item_outer {
        display: flex;
        flex-wrap: wrap;
        max-height: 130px;
        overflow-y: scroll;
        max-height: 46px;
      }
      .sel_item {
        display: flex;
        align-items: center;
        padding: 8px 14px;
        background: rgba(0, 108, 80, 0.1);
        border-radius: 8px 8px 8px 8px;
        white-space: nowrap;
        margin-right: 12px;
        margin-top: 6px;
        margin-bottom: 6px;

        img {
          width: 6.33px;
          height: 7.66px;
          margin-left: 16px;
        }
        span {
          // font-weight: 290;
          font-size: 16px;
          color: #006c50;
          line-height: 16px;
        }
      }
    }
    .el-form-item__error {
      left: 22px !important;
      // display: none;
    }
    .form_input_row_sel {
      cursor: pointer;
      padding-left: 24px;
      display: flex;
      align-items: center;
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background: #cccccc;
        border-radius: 4px;
      }
    }
    .form_sel_box {
      position: absolute;
      top: 60px;
      left: 0;
      background: #ffffff;
      box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.1216);
      border-radius: 11px 11px 11px 11px;
      padding: 26px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      max-height: 278px;
      overflow-y: scroll;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      z-index: 99;

      .form_sel_box_item {
        border-radius: 8px 8px 8px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px;

        span {
          // font-weight: 290;
          font-size: 16px;
          color: #222222;
          line-height: 16px;
        }
      }
      .form_sel_box_item:hover {
        background: rgba(0, 108, 80, 0.1);

        span {
          color: rgba(0, 108, 80, 1);
        }
      }
      .form_sel_box_item_active {
        background: rgba(0, 108, 80, 0.1);

        span {
          color: rgba(0, 108, 80, 1);
        }
      }
    }
    .form_sel_box_show {
      padding: 26px;
      height: auto;
      opacity: 1;
    }
  }
  .dialog_btn {
    width: calc(100% + 12px);
    position: relative;
    left: -6px;
    height: 60px;
    background: #006c50;
    border-radius: 80px;
    // font-weight: 700;
    font-size: 18px;
    color: #222222;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.8);
    border-width: 6px;
    margin-top: 10px;
    letter-spacing: 1px;
    // max-width: 546px;
  }
}

.el-dialog {
  border-radius: 9px;
}
.place_span {
  font-weight: 400;
  font-size: 16px;
  color: #8d9296;
  line-height: 16px;
}
//在样式表中定义一个 ::placeholder 伪类选择器，指定需要修改的属性和值，例如修改占位符文本颜色为灰色（#888888）。
.el-input__inner::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #8d9296;
  line-height: 16px;
}

.success_style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 35px;
  .success_style_img {
    img {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: 400;
    font-size: 26px;
    color: #222222;
    line-height: 26px;
    margin-top: 46px;
  }
}

.code_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 6px);
  box-sizing: border-box;
  .el-input__inner {
    background: #f8f8f8 !important;
    border: none;
    border-radius: 23px 23px 23px 23px;
    padding-left: 18px;
    height: 46px;
  }
}

.code_img {
  display: flex;
  align-items: center;
  img {
    width: 130px;
    height: 46px;
    // height: 36px;
    object-fit: contain;
  }
}
</style>