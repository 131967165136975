<template>
  <div class="footer_outer_box" style="position: relative; top: 20px">
    <div v-if="footInfo.config" class="reservation">
      <p class="reservation_top">{{ footInfo.config.foot_title }}</p>
      <p class="reservation_desc">
        {{ footInfo.config.foot_desc }}
      </p>
      <button @click="openForm" class="reservation_btn">
        {{ footInfo.config.foot_btn_text }}
      </button>
    </div>
    <div v-if="footInfo.config" class="footer">
      <div class="footer_block1">
        <div class="footer_block1_column1">
          <div class="footer_block1_column1_top">
            <div class="footer_block1_column1_top_left">
              <img
                class="footer_block1_column1_top_left_logo"
                :src="$commonJs.formatImgSrc(footInfo.config.logo_bottom)"
                alt=""
              />
              <p class="footer_descp yellow_p">
                <span>服务电话：</span>
                <span>{{ footInfo.config.service_mobile }}</span>
              </p>
              <p class="footer_descp">
                招聘邮箱：{{ footInfo.config.service_email }}
              </p>
              <p class="footer_descp">
                售后邮箱：{{ footInfo.config.after_sales_email }}
              </p>
            </div>
            <div class="footer_block1_column1_top_right">
              <div class="footer_block1_column1_top_right_column">
                <img
                  :src="$commonJs.formatImgSrc(footInfo.config.official_wechat)"
                  alt=""
                />
                <span>微信公众号</span>
              </div>
              <div class="footer_block1_column1_top_right_column">
                <img
                  :src="
                    $commonJs.formatImgSrc(
                      footInfo.config.official_customer_service
                    )
                  "
                  alt=""
                />
                <span>官方客服</span>
              </div>
            </div>
          </div>
          <div class="footer_block1_column1_bottom">
            <p class="footer_descp">
              业务专线：{{ footInfo.config.business_dedicated_line }}
            </p>
            <p class="footer_descp">
              销售邮箱：{{ footInfo.config.sale_email }}
            </p>
            <p class="footer_descp">
              公司地址：{{ footInfo.config.company_address }}
            </p>
            <p class="footer_descp">
              传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;真：{{
                footInfo.config.fax
              }}
            </p>
            <p class="footer_descp">
              邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;编：{{
                footInfo.config.postal_code
              }}
            </p>
          </div>
        </div>
        <div class="footer_block1_column2">
          <p class="footer_title">产品中心</p>
          <div class="footer_block1_column2_products">
            <div
              v-for="(item, index) in productList"
              :key="index"
              class="footer_block1_column2_products_column"
            >
              <template v-for="(childItem, childIndex) in item.list">
                <span
                  :key="childIndex"
                  @click="navClick(childItem, 0)"
                  class="footer_block1_column2_products_item"
                  >{{ childItem.title }}</span
                >
              </template>
            </div>
          </div>
        </div>

        <div class="footer_block1_column3">
          <p class="footer_title">解决方案</p>
          <div v-if="footInfo.programme" class="footer_block1_column3_bot">
            <template
              v-for="(childItem, childIndex) in footInfo.programme.list"
            >
              <p
                :key="childIndex"
                @click="navClick(childItem, 1)"
                class="footer_block1_column3_bot_item"
              >
                {{ childItem.sel_title }}
              </p>
            </template>
          </div>
        </div>

        <div class="footer_block1_column3">
          <p class="footer_title">发现更多</p>
          <div v-if="footInfo.about" class="footer_block1_column3_bot">
            <p
              @click="
                navClick(
                  {
                    id: 'intro',
                  },
                  2
                )
              "
              class="footer_block1_column3_bot_item"
            >
              {{ footInfo.about.cates.about_desc }}
            </p>
            <p
              @click="
                navClick(
                  {
                    id: 'product',
                  },
                  2
                )
              "
              class="footer_block1_column3_bot_item"
            >
              {{ footInfo.about.cates.about_goods }}
            </p>
            <p
              @click="
                navClick(
                  {
                    id: 'core',
                  },
                  2
                )
              "
              class="footer_block1_column3_bot_item"
            >
              {{ footInfo.about.cates.core_values }}
            </p>
            <p
              @click="
                navClick(
                  {
                    id: 'certificate',
                  },
                  2
                )
              "
              class="footer_block1_column3_bot_item"
            >
              {{ footInfo.about.cates.qualifications }}
            </p>
          </div>
        </div>

        <div class="footer_block1_column3">
          <p class="footer_title">热点文章</p>
          <div class="footer_block1_column3_bot">
            <template v-for="(childItem, childIndex) in footInfo.hot_news">
              <p
                @click="navClick(childItem, 3)"
                :key="childIndex"
                class="footer_block1_column3_bot_item"
              >
                {{ childItem.title }}
              </p>
            </template>
          </div>
        </div>
      </div>

      <div class="footer_block2">
        <p>沈阳普惠万通科技有限公司</p>
      </div>

      <div class="footer_block3">
        <span>{{ footInfo.config.site_copyright }}</span>
        <span style="cursor: pointer" @click="toCopyRight(1)">{{
          footInfo.config.site_record_number
        }}</span>
        <div
          style="cursor: pointer"
          @click="toCopyRight(2)"
          class="footer_block3_right"
        >
          <img src="../assets/img/gongan.png" alt="" />
          <span>{{ footInfo.config.public_site_record_number }}</span>
        </div>
      </div>
    </div>

    <FormComponents
      @handlerCancel="handlerCancel"
      :dialogVisible="dialogVisible"
    ></FormComponents>
  </div>
</template>

<script>
import { getFootInfo } from "@/network/public";

export default {
  name: "SecurityFooter",

  data() {
    return {
      dialogVisible: false,
      footInfo: {},
      productList: [],
    };
  },

  mounted() {
    this.getInfo();
  },

  methods: {
    navClick(item, hase) {
      let url = "";
      let id = item.id;
      switch (hase) {
        case 0:
          url = "/products";
          break;
        case 1:
          url = "/solutions";
          break;
        case 2:
          url = "/aboutUs";
          break;
        case 3:
          url = "/newsDetail";
          break;
      }
      if (url) {
        this.$router.push({
          path: url,
          query: {
            id: id,
          },
        });
      }
    },
    getInfo() {
      getFootInfo()
        .then((res) => {
          if (res.status == 200) {
            this.footInfo = res.data;
            if (this.footInfo.goods.list.length > 0) {
              this.productList = [];
              const groupedArray = this.footInfo.goods.list.reduce(
                (acc, current, index) => {
                  const chunkIndex = Math.floor(index / 6); // 计算当前元素应该属于哪个分组

                  if (!acc[chunkIndex]) {
                    acc[chunkIndex] = { list: [] }; // 如果这个分组还没有在结果数组中出现，则初始化它
                  }

                  acc[chunkIndex].list.push(current); // 将当前元素添加到对应的分组中

                  if ((index + 1) % 6 === 0) {
                    // 如果当前元素是某个分组的最后一个元素（即每组的第6个元素），
                    // 可以选择在这里做一些额外的处理，但在这个例子中我们不需要
                  }

                  return acc;
                },
                []
              ); // 使用空数组作为累积器的初始值
              this.productList = groupedArray;
            }
          }
        })
        .catch((e) => {});
    },
    handlerCancel() {
      this.dialogVisible = false;
    },
    toCopyRight(hase) {
      if (hase == 1) {
        window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
      } else {
        window.open("https://beian.mps.gov.cn/#/query/webSearch", "_blank");
      }
    },
    openForm() {
      this.dialogVisible = true;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reservation {
  background-image: url("@/assets/img/yuyuebeijing.png");
  background-size: 100% 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 49px 0 68px 0;
  &_top {
    font-weight: 400;
    font-size: 32px;
    color: #ffffff;
    line-height: 32px;
  }
  &_desc {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 14px;
    margin-top: 19px;
  }
  &_btn {
    width: 170px;
    height: 50px;
    border-radius: 25px 25px 25px 25px;
    border: 5px solid #ffffff;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    line-height: 18px;
    background: none;
    margin-top: 40px;
    cursor: pointer;
    transition: all 0.3s ease;
    letter-spacing: 1.1px;
  }
  .reservation_btn:hover {
    // transform: scale(1.1);
    background: rgba(34, 128, 103, 1);
  }
}

.footer {
  background: #151515;

  .footer_block1 {
    padding-top: 56px;
    padding-bottom: 23px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #3e3e3e;
    .footer_descp {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 14px;
      opacity: 76%;
      display: flex;
      align-items: center;
    }
    // .footer_descp:hover {
    //   color: rgba(255, 183, 0, 1);
    // }
    .footer_title {
      font-weight: 700;
      font-size: 21px;
      color: #ffffff;
      line-height: 21px;
      white-space: nowrap;
    }
    .footer_block1_column1 {
      &_top {
        display: flex;
        &_left {
          &_logo {
            width: 77px;
            height: 27px;
          }
          .yellow_p {
            color: rgba(255, 183, 0, 1);
          }
          p {
            margin-top: 14px;
          }
        }
        &_right {
          display: flex;
          align-items: center;
          img {
            width: 72px;
            height: 72px;
          }
          span {
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 14px;
            margin-top: 9px;
            opacity: 76%;
          }

          &_column {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 21px;
          }
        }
      }

      &_bottom {
        p {
          margin-top: 14px;
        }
      }
    }

    .footer_block1_column2 {
      margin-left: 100px;
      &_products {
        width: 100%;
        margin-top: 26px;
        display: flex;
        // align-items: center;
        &_column {
          display: grid;
          grid-row-gap: 14px;
          margin-left: 32px;
        }
        .footer_block1_column2_products_column:first-child {
          margin-left: 0;
        }
        &_item {
          white-space: nowrap;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
          opacity: 76%;
          cursor: pointer;
        }
        .footer_block1_column2_products_item:hover {
          color: rgba(255, 183, 0, 1);
        }
      }
    }

    .footer_block1_column3 {
      margin-left: 54px;
      &_bot {
        margin-top: 26px;
        &_item {
          margin-top: 14px;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          line-height: 14px;
          opacity: 76%;
          cursor: pointer;
        }
        .footer_block1_column3_bot_item:hover {
          color: rgba(255, 183, 0, 1);
        }
      }
    }
  }

  .footer_block2 {
    padding: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      line-height: 18px;
      opacity: 76%;
    }
  }
  .footer_block3 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 1);
    padding: 22px;
    span {
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      line-height: 16px;
      opacity: 76%;
      margin-left: 30px;
    }

    .footer_block3_right {
      display: flex;
      align-items: center;
      margin-left: 30px;

      img {
        width: 20px;
        height: 21px;
      }
      span {
        margin-left: 8px;
      }
    }
  }
}
</style>