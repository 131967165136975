<template>
  <div class="swiper-container">
    <swiper
      class="swiper-container_2"
      ref="brandsSwiper"
      :options="swiperOption"
      @mouseenter.native="enter"
      @mouseleave.native="leave"
    >
      <swiper-slide
        class="swiper-wrapper"
        v-for="(item, index) in loopSwiperList"
        :key="index"
      >
        <div @click="brandClick(item, index)" class="customers_card">
          <div class="customers_card_top">
            <img
              class="imgStyle"
              :src="$commonJs.formatImgSrc(item.image)"
              alt=""
            />
          </div>
          <div class="customers_card_bot">
            <span class="customers_card_bot_title">{{ item.name }}</span>
            <img
              class="customers_card_bot_logo"
              :src="$commonJs.formatImgSrc(item.logo)"
              alt=""
            />
          </div>
        </div>
      </swiper-slide>
      <!-- 奇数时添加一条 -->
      <!-- <swiper-slide
        v-if="loopSwiperList.length % 2 == 1 && loopSwiperList.length > 6"
      >
        <div @click="brandClick(loopSwiperList[0], 0)" class="customers_card">
          <div class="customers_card_top">
            <img
              class="imgStyle"
              :src="$commonJs.formatImgSrc(item.image)"
              alt=""
            />
          </div>
          <div class="customers_card_bot">
            <span class="customers_card_bot_title">{{ item.name }}</span>
            <img
              class="customers_card_bot_logo"
              :src="$commonJs.formatImgSrc(item.logo)"
              alt=""
            />
          </div>
        </div>
      </swiper-slide> -->
    </swiper>
    <div
      v-if="1 == 2"
      @mouseover="btnMouseOver"
      @mouseout="btnMouseOut"
      class="brands-btn-box"
      style=""
    >
      <div @click="slidePrev" class="brands-prev">
        <img class="icon" src="@/assets/img/weixuanzhongzuo.png" alt="" />
        <img class="active_icon" src="@/assets/img/xuanzhongzuo.png" alt="" />
      </div>
      <div @click="slideNext" class="brands-next">
        <img class="icon" src="@/assets/img/weixuanzhongyou.png" alt="" />
        <img
          class="active_icon"
          style="transform: rotate(180deg)"
          src="../assets/img/xuanzhongzuo.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "SwiperList",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    loopSwiperList: {
      require: false,
      default: () => [],
    },
  },
  watch: {
    loopSwiperList: {
      handler(newValue, old) {
        this.slideTo();
      },
      deep: true, // 是否深度监听
      immediate: true, // 是否在组件创建时立即执行回调函数
    },
  },
  data() {
    return {
      swiperOption: {
        preventClicksPropagation: true,
        speed: 3000, //匀速时间
        freeMode: true,
        loop: false,
        slidesOffsetBefore: 0, //左边偏移量
        autoplay: false,
        breakpoints: {
          //   320: {
          //     //当屏幕宽度大于等于320
          //     slidesPerView: 1,
          //     slidesOffsetBefore: 100,
          //   },
          //   320: {
          //     //当屏幕宽度大于等于320
          //     slidesPerView: 1.85,
          //     slidesOffsetBefore: 100,
          //   },
          //   400: {
          //     //当屏幕宽度大于等于320
          //     slidesPerView: 2,
          //     slidesOffsetBefore: 100,
          //   },
          //   776: {
          //     //当屏幕宽度大于等于776
          //     slidesPerView: 2.8,
          //     slidesOffsetBefore: 150,
          //   },
          1000: {
            //当屏幕宽度大于等于1000
            slidesPerView: 3.3,
          },
          1200: {
            //当屏幕宽度大于等于1200
            slidesPerView: 4.5,
          },
          1500: {
            //当屏幕宽度大于等于1200
            slidesPerView: 5.5,
          },
          2980: {
            //当屏幕宽度大于等于1200
            slidesPerView: 6.5,
          },
        },
        // slidesPerView: 5.5,
        spaceBetween: 13, //间隔
        loopFillGroupWithBlank: true,
        normalizeSlideIndex: true,
        allowTouchMove: false, //不允许触摸滑动
        centeredSlides: true, //当slide的总数小于slidesPerView时，slide居中
        navigation: {
          nextEl: ".brands-next",
          prevEl: ".brands-prev",
        },
      },
      nextTime: 0,
      nextTransForm: "",
    };
  },
  computed: {
    swiper() {
      if (this.$refs.brandsSwiper) {
        return this.$refs.brandsSwiper.$swiper;
      } else {
        return "";
      }
    },
  },
  methods: {
    btnMouseOver() {
      // this.enter();
    },
    btnMouseOut() {
      // this.leave();
    },
    brandClick(item, index) {
      console.log(item, index);
      // 类型 1 文章 2 公众号链接跳转
      if (item.type == 2) {
        if (item.url) {
          window.open(item.url, "_blank");
        }
      } else if (item.type == 1) {
        if (item.typessss == 1) {
          this.$router.push({
            path: "/caseDetail",
            query: {
              id: item.id,
              from: "group",
              lastPage: "index",
            },
          });
        } else if (item.typessss == 2) {
          this.$router.push({
            path: "/caseDetail",
            query: {
              id: item.id,
              from: "brand",
              lastPage: "index",
            },
          });
        }
      }
    },
    slidePrev() {
      this.swiper.slidePrev();
      this.$forceUpdate();
    },
    slideNext() {
      console.log(this.swiper);
      this.swiperOption.speed = 2500;
      this.swiper.slideNext();
      this.$forceUpdate();
    },
    slideTo() {
      let that = this;
      if (this.loopSwiperList.length >= 1) {
        this.swiperOption.loop = true;
        this.swiperOption.autoplay = {
          delay: 0, //停顿时间
          stopOnLastSlide: false,
          disableOnInteraction: false,
        };
        setTimeout(function () {
          if (that.swiper) {
            that.swiper.slideNext();
          }
        }, 1000);
      } else {
        let loopIndex = Math.floor(this.loopSwiperList.length / 2);
        setTimeout(function () {
          if (that.swiper) {
            that.swiper.slideTo(loopIndex, 500, false);
          }
        }, 1000);
      }
    },
    enter() {
      // if (this.loopSwiperList.length <= 6) {
      //   return false;
      // }
      console.log("enter");
      // return;
      let that = this;
      if (that.$refs.brandsSwiper) {
        this.nextTransForm = document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName("swiper-wrapper")[0].style.transform;
        // 轮播图原本应移动到的位置
        let nextTransPosition =
          -1 *
          parseInt(
            document
              .getElementsByClassName("swiper-container_2")[0]
              .getElementsByClassName("swiper-wrapper")[0]
              .style.transform.split("translate3d(")[1]
              .split("px")[0]
          );

        // 鼠标悬浮时时轮播图位置
        let nowTransPosition =
          -1 *
          parseInt(
            window
              .getComputedStyle(
                document
                  .getElementsByClassName("swiper-container_2")[0]
                  .getElementsByClassName("swiper-wrapper")[0],
                false
              )
              ["transform"].split("1, ")[2]
              .split(",")[0]
          );
        // 存放鼠标悬浮时轮播图的真实transform属性（非行内属性）
        let nowTransForm = window.getComputedStyle(
          document
            .getElementsByClassName("swiper-container_2")[0]
            .getElementsByClassName("swiper-wrapper")[0],
          false
        )["transform"];
        // 计算轮播图从暂停位置移动到原本应到的位置所用的时间（370是我自定义的每个slide的宽度）
        this.nextTime = 5500 * ((nextTransPosition - nowTransPosition) / 370);
        // 改变行内transform属性
        document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName("swiper-wrapper")[0].style.transform =
          nowTransForm;
        // 不写也没关系
        document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName(
            "swiper-wrapper"
          )[0].style.transitionDuration = "0ms";
        this.swiper.autoplay.stop();
        // that.$refs.brandsSwiper.$swiper.autoplay.stop();
      }
    },
    leave() {
      // if (this.loopSwiperList.length <= 6) {
      //   return false;
      // }
      console.log("leave");
      // return;
      let that = this;
      if (that.$refs.brandsSwiper) {
        // 恢复原样
        document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName("swiper-wrapper")[0].style.transform =
          this.nextTransForm;
        document
          .getElementsByClassName("swiper-container_2")[0]
          .getElementsByClassName(
            "swiper-wrapper"
          )[0].style.transitionDuration = this.nextTime + "ms";
        this.swiper.autoplay.start();
        that.$refs.brandsSwiper.$swiper.autoplay.start();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: linear;
  /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
}

.imgStyle {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 244.93px;
}

.customers_card {
  background: #ffffff;
  box-shadow: 0px 2px 13px 0px rgba(161, 161, 161, 0.1608);
  border-radius: 18px 18px 18px 18px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  min-height: 442.32px;
  .customers_card_top {
    border-radius: 18px 18px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    img {
      width: 100%;
      // height: 279.5px;
      height: 244.93px;
    }
  }
  .customers_card_bot {
    padding: 42px 31px;
    display: flex;
    flex-direction: column;
    width: 100%;
    &_title {
      font-weight: 700;
      font-size: 25px;
      color: #222222;
      line-height: 25px;
      user-select: all;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
    &_logo {
      width: 112px;
      height: 86px;
      object-fit: contain;
    }
  }
}
.brands-btn-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 54px;
  margin-top: 30px;
}
.brands-prev {
  cursor: pointer;
  z-index: 1;
  img {
    width: 40px;
    height: 40px;
  }
  .icon {
    display: block;
  }
  .active_icon {
    display: none;
  }
}

.brands-next {
  cursor: pointer;
  z-index: 1;
  margin-left: 10px;
  .icon {
    display: block;
  }
  .active_icon {
    display: none;
  }
  img {
    width: 40px;
    height: 40px;
  }
}
.brands-prev:hover {
  .icon {
    display: none;
  }
  .active_icon {
    display: block;
  }
}
.brands-next:hover {
  .icon {
    display: none;
  }
  .active_icon {
    display: block;
  }
}
</style>