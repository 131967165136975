import Vue from "vue";
import * as config from "@/config";
import * as commonJS from "./common.js";

Vue.mixin({
	computed: {
		$config() {
			return config;
		},
		$commonJs() {
			return commonJS;
		},
	},
});