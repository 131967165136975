<template>
  <div class="error_msg">
    <!-- {{ msg }} -->
    <img
      style="width: 100%; height: 100%; object-fit: contain"
      :src="msg"
      alt=""
    />
  </div>
</template>

<script>
import { BASE_URL } from "@/config";
export default {
  name: "SecurityEmpty",

  data() {
    return {
      msg: "券铭官网维护中，稍后即将开放，静请期待",
    };
  },

  mounted() {
    let queryParams = this.$route.query;
    if (queryParams.msg) {
      this.msg = queryParams.msg;
    }
  },

  methods: {},
};
</script>
<style scoped>
.navbar {
  display: none;
}
.footer_outer_box {
  display: none;
}

#rightfix {
  display: none;
}
.error_msg {
  margin: 0 auto;
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  text-align: center;
}
</style>