import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'
import Products from '../views/products.vue'
import Solutions from '../views/solutions.vue'
import Customers from '../views/customers.vue'
import News from '../views/news.vue'
import NewsDetail from '../views/newsDetail.vue'
import CaseDetail from '../views/caseDetail.vue'
import AboutUs from '../views/aboutUs.vue'
import Empty from '../views/empty.vue'
import NotFound from '../views/404.vue'
import FormPage from '../views/formPage.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/products',
    name: 'products',
    component: Products
  },
  {
    path: '/solutions',
    name: 'solutions',
    component: Solutions
  },
  {
    path: '/customers',
    name: 'customers',
    component: Customers
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: NewsDetail
  },
  {
    path: '/caseDetail',
    name: 'caseDetail',
    component: CaseDetail
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: AboutUs
  },
  {
    name: 'notfound',
    path: '/404',
    component: NotFound
  },
  {
    name: 'empty',
    path: '/empty',
    component: Empty
  },
  {
    name: 'form',
    path: '/form',
    component: FormPage
  },
  {
    /**
     * base: process.env.BASE_URL,
     * 进行我们的路由匹配，通俗来讲就是比如我们是http://localhost:8080
     * 匹配我们/后面的如果你乱写的话就会重定向我们的404路由
     * 举个例子
     *   { path: '/user-:afterUser(.*)',      redirect: '/notfound},
     * 匹配我们/user后面的内容，如果你乱写的话就会进入404
     */
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
export default router

// 忽略NavigationDuplicated
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err instanceof Error && err.name.includes("NavigationDuplicated")) {
      return
    } else {
      console.error(err)
    }
  })
}
