var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"common_top"}),(_vm.info.contents)?_c('Level2Banner',{attrs:{"title":_vm.info.contents.title,"bannerImage":_vm.info.contents.image,"outerUrl":_vm.info.contents.url,"btnText":_vm.info.btn_text,"level2Title":_vm.info.contents.title_small,"descList":_vm.info.contents.desc}}):_vm._e(),_c('div',{staticClass:"customerscontent"},[_c('div',{staticClass:"max_width_1200",attrs:{"id":"listTop"}},[_c('div',{staticClass:"customers_tabs"},[_c('div',{staticClass:"customers_tabs_row"},[_vm._m(0),_c('div',{staticClass:"customers_tabs_row_right"},[_vm._l((_vm.allInfo.scene),function(item,index){return [_c('div',{key:index,staticClass:"customers_tabs_row_right_item",class:index == _vm.sceneIndex
                    ? 'customers_tabs_row_right_item_active'
                    : '',on:{"click":function($event){return _vm.sceneClick(item, index)}}},[_c('span',[_vm._v(_vm._s(item.name))])])]})],2)]),_c('div',{staticClass:"customers_tabs_row"},[_vm._m(1),_c('div',{staticClass:"customers_tabs_row_right"},[_vm._l((_vm.allInfo.group),function(item,index){return [_c('div',{key:index,staticClass:"customers_tabs_row_right_item",class:index == _vm.groupIndex
                    ? 'customers_tabs_row_right_item_active'
                    : '',on:{"click":function($event){return _vm.groupClick(item, index)}}},[_c('span',[_vm._v(_vm._s(item.name))])])]})],2)])]),_c('div',{staticClass:"customers_cards"},[_vm._l((_vm.list),function(item,index){return [_c('div',{key:index,staticClass:"customers_card",on:{"click":function($event){return _vm.listTap(item, index)}}},[_c('div',{staticClass:"customers_card_top"},[_c('img',{attrs:{"src":_vm.$commonJs.formatImgSrc(item.image),"alt":""}})]),_c('div',{staticClass:"customers_card_bot"},[_c('span',{staticClass:"customers_card_bot_title"},[_vm._v(_vm._s(item.name))]),_c('img',{staticClass:"customers_card_bot_logo",attrs:{"src":_vm.$commonJs.formatImgSrc(item.logo),"alt":""}}),_c('div',{staticClass:"customers_card_bot_tags"},_vm._l((item.scene),function(childItem,childIndex){return _c('div',{key:childIndex,staticClass:"customers_card_bot_tags_item"},[_c('img',{attrs:{"src":require("../assets/img/lvduihao.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(childItem))])])}),0)])])]})],2),(_vm.pageTotal > 0)?_c('div',{staticClass:"pagination"},[(_vm.queryForm.page != 1)?_c('button',{staticClass:"next_btn common_btn_style",on:{"click":function($event){return _vm.handleCurrentChange(_vm.queryForm.page - 1)}}},[_vm._v(" 上一页 ")]):_vm._e(),(_vm.queryForm.page != 1 && _vm.queryForm.page != _vm.maxPage)?_c('span',{staticStyle:{"display":"block","width":"20px"}}):_vm._e(),(_vm.queryForm.page == 1 || _vm.queryForm.page != _vm.maxPage)?_c('button',{staticClass:"next_btn common_btn_style",on:{"click":function($event){return _vm.handleCurrentChange(_vm.queryForm.page + 1)}}},[_vm._v(" 下一页 ")]):_vm._e()]):_vm._e()])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customers_tabs_row_left"},[_c('span',[_vm._v("使用场景")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customers_tabs_row_left"},[_c('span',[_vm._v("应用集团")])])
}]

export { render, staticRenderFns }