var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_outer_box",staticStyle:{"position":"relative","top":"20px"}},[(_vm.footInfo.config)?_c('div',{staticClass:"reservation"},[_c('p',{staticClass:"reservation_top"},[_vm._v(_vm._s(_vm.footInfo.config.foot_title))]),_c('p',{staticClass:"reservation_desc"},[_vm._v(" "+_vm._s(_vm.footInfo.config.foot_desc)+" ")]),_c('button',{staticClass:"reservation_btn",on:{"click":_vm.openForm}},[_vm._v(" "+_vm._s(_vm.footInfo.config.foot_btn_text)+" ")])]):_vm._e(),(_vm.footInfo.config)?_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer_block1"},[_c('div',{staticClass:"footer_block1_column1"},[_c('div',{staticClass:"footer_block1_column1_top"},[_c('div',{staticClass:"footer_block1_column1_top_left"},[_c('img',{staticClass:"footer_block1_column1_top_left_logo",attrs:{"src":_vm.$commonJs.formatImgSrc(_vm.footInfo.config.logo_bottom),"alt":""}}),_c('p',{staticClass:"footer_descp yellow_p"},[_c('span',[_vm._v("服务电话：")]),_c('span',[_vm._v(_vm._s(_vm.footInfo.config.service_mobile))])]),_c('p',{staticClass:"footer_descp"},[_vm._v(" 招聘邮箱："+_vm._s(_vm.footInfo.config.service_email)+" ")]),_c('p',{staticClass:"footer_descp"},[_vm._v(" 售后邮箱："+_vm._s(_vm.footInfo.config.after_sales_email)+" ")])]),_c('div',{staticClass:"footer_block1_column1_top_right"},[_c('div',{staticClass:"footer_block1_column1_top_right_column"},[_c('img',{attrs:{"src":_vm.$commonJs.formatImgSrc(_vm.footInfo.config.official_wechat),"alt":""}}),_c('span',[_vm._v("微信公众号")])]),_c('div',{staticClass:"footer_block1_column1_top_right_column"},[_c('img',{attrs:{"src":_vm.$commonJs.formatImgSrc(
                    _vm.footInfo.config.official_customer_service
                  ),"alt":""}}),_c('span',[_vm._v("官方客服")])])])]),_c('div',{staticClass:"footer_block1_column1_bottom"},[_c('p',{staticClass:"footer_descp"},[_vm._v(" 业务专线："+_vm._s(_vm.footInfo.config.business_dedicated_line)+" ")]),_c('p',{staticClass:"footer_descp"},[_vm._v(" 销售邮箱："+_vm._s(_vm.footInfo.config.sale_email)+" ")]),_c('p',{staticClass:"footer_descp"},[_vm._v(" 公司地址："+_vm._s(_vm.footInfo.config.company_address)+" ")]),_c('p',{staticClass:"footer_descp"},[_vm._v(" 传       真："+_vm._s(_vm.footInfo.config.fax)+" ")]),_c('p',{staticClass:"footer_descp"},[_vm._v(" 邮       编："+_vm._s(_vm.footInfo.config.postal_code)+" ")])])]),_c('div',{staticClass:"footer_block1_column2"},[_c('p',{staticClass:"footer_title"},[_vm._v("产品中心")]),_c('div',{staticClass:"footer_block1_column2_products"},_vm._l((_vm.productList),function(item,index){return _c('div',{key:index,staticClass:"footer_block1_column2_products_column"},[_vm._l((item.list),function(childItem,childIndex){return [_c('span',{key:childIndex,staticClass:"footer_block1_column2_products_item",on:{"click":function($event){return _vm.navClick(childItem, 0)}}},[_vm._v(_vm._s(childItem.title))])]})],2)}),0)]),_c('div',{staticClass:"footer_block1_column3"},[_c('p',{staticClass:"footer_title"},[_vm._v("解决方案")]),(_vm.footInfo.programme)?_c('div',{staticClass:"footer_block1_column3_bot"},[_vm._l((_vm.footInfo.programme.list),function(childItem,childIndex){return [_c('p',{key:childIndex,staticClass:"footer_block1_column3_bot_item",on:{"click":function($event){return _vm.navClick(childItem, 1)}}},[_vm._v(" "+_vm._s(childItem.sel_title)+" ")])]})],2):_vm._e()]),_c('div',{staticClass:"footer_block1_column3"},[_c('p',{staticClass:"footer_title"},[_vm._v("发现更多")]),(_vm.footInfo.about)?_c('div',{staticClass:"footer_block1_column3_bot"},[_c('p',{staticClass:"footer_block1_column3_bot_item",on:{"click":function($event){return _vm.navClick(
                {
                  id: 'intro',
                },
                2
              )}}},[_vm._v(" "+_vm._s(_vm.footInfo.about.cates.about_desc)+" ")]),_c('p',{staticClass:"footer_block1_column3_bot_item",on:{"click":function($event){return _vm.navClick(
                {
                  id: 'product',
                },
                2
              )}}},[_vm._v(" "+_vm._s(_vm.footInfo.about.cates.about_goods)+" ")]),_c('p',{staticClass:"footer_block1_column3_bot_item",on:{"click":function($event){return _vm.navClick(
                {
                  id: 'core',
                },
                2
              )}}},[_vm._v(" "+_vm._s(_vm.footInfo.about.cates.core_values)+" ")]),_c('p',{staticClass:"footer_block1_column3_bot_item",on:{"click":function($event){return _vm.navClick(
                {
                  id: 'certificate',
                },
                2
              )}}},[_vm._v(" "+_vm._s(_vm.footInfo.about.cates.qualifications)+" ")])]):_vm._e()]),_c('div',{staticClass:"footer_block1_column3"},[_c('p',{staticClass:"footer_title"},[_vm._v("热点文章")]),_c('div',{staticClass:"footer_block1_column3_bot"},[_vm._l((_vm.footInfo.hot_news),function(childItem,childIndex){return [_c('p',{key:childIndex,staticClass:"footer_block1_column3_bot_item",on:{"click":function($event){return _vm.navClick(childItem, 3)}}},[_vm._v(" "+_vm._s(childItem.title)+" ")])]})],2)])]),_vm._m(0),_c('div',{staticClass:"footer_block3"},[_c('span',[_vm._v(_vm._s(_vm.footInfo.config.site_copyright))]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCopyRight(1)}}},[_vm._v(_vm._s(_vm.footInfo.config.site_record_number))]),_c('div',{staticClass:"footer_block3_right",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCopyRight(2)}}},[_c('img',{attrs:{"src":require("../assets/img/gongan.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.footInfo.config.public_site_record_number))])])])]):_vm._e(),_c('FormComponents',{attrs:{"dialogVisible":_vm.dialogVisible},on:{"handlerCancel":_vm.handlerCancel}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer_block2"},[_c('p',[_vm._v("沈阳普惠万通科技有限公司")])])
}]

export { render, staticRenderFns }