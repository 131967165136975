<template>
  <div>
    <!-- -->
    <div class="level2banner">
      <swiper :options="swiperOption">
        <swiper-slide v-for="item in 1" :key="item">
          <img :src="$commonJs.formatImgSrc(bannerImage)" />
        </swiper-slide>
      </swiper>
      <div class="banner_content">
        <div class="max_width_1200">
          <template v-if="from != 'aboutus'">
            <div style="max-width: 90%; z-index: 11; position: relative">
              <div style="display: flex; align-items: center">
                <h1 class="banner_content_p1">{{ title }}</h1>
                <span
                  v-if="smallTitle"
                  style="margin-left: 10px; font-size: 26px"
                  >{{ smallTitle }}</span
                >
              </div>
              <h1
                v-if="level2Title"
                class="banner_content_p1 banner_content_p2"
              >
                {{ level2Title }}
              </h1>
              <div
                v-for="(item, index) in descList"
                :key="index"
                class="banner_content_prow"
                :style="index == 0 ? 'margin-top:0' : ''"
              >
                <div class="banner_content_prow_dot_outer">
                  <span class="banner_content_prow_dot"></span>
                </div>
                <p class="banner_content_p3">{{ item.title }}</p>
              </div>
              <button
                @click="openUrl"
                class="banner_content_btn common_btn_style"
              >
                {{ btnText }}
              </button>
            </div>
          </template>
          <template v-else>
            <div
              style="
                max-width: 90%;
                z-index: 11;
                position: relative;
                display: flex;
              "
            >
              <div class="aboutus_h">
                <h1 class="banner_content_p1 aboutus_h1">{{ title }}</h1>
                <p class="banner_content_p1 banner_content_p2 aboutus_h2">
                  {{ level2Title }}
                </p>
                <button
                  @click="openUrl"
                  class="banner_content_btn common_btn_style"
                  style="margin-top: 41px"
                >
                  {{ btnText }}
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <FormComponents
      @handlerCancel="handlerCancel"
      :dialogVisible="dialogVisible"
    ></FormComponents>
  </div>
</template>

<script>
export default {
  name: "SecurityLevel2Banner",
  props: {
    from: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    level2Title: {
      type: String,
      default: "",
    },
    smallTitle: {
      type: String,
      default: "",
    },
    descList: {
      type: Array,
      default: () => [],
    },
    bannerImage: {
      type: String,
      default: "",
    },
    outerUrl: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      swiperOption: {
        // loop:true,
        observer: true, //开启动态检查器，监测swiper和slide
        observeParents: true, //监测Swiper 的祖/父元素
        observeSlideChildren: true,
        allowTouchMove: false, //不允许触摸滑动
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          stopOnLastSlide: false,
        },
        pagination: {
          el: ".swiper_pagination",
        },
      },
    };
  },

  mounted() {},

  methods: {
    openUrl() {
      if (this.outerUrl) {
        window.open(this.outerUrl, "_blank");
      } else {
        this.openForm();
      }
    },
    handlerCancel() {
      this.dialogVisible = false;
    },
    openForm() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss">
.level2banner {
  position: relative;
  .banner_content {
    position: absolute;
    top: 218px;
    display: flex;
    flex-direction: column;
    left: 0;
    width: calc(100% - 0px);
    box-sizing: border-box;

    .banner_content_p1 {
      font-weight: 700;
      font-size: 46px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 25px;
      letter-spacing: 1px;
    }
    .banner_content_p2 {
      margin-bottom: 22px;
    }
    .aboutus_h {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 60%;

      .aboutus_h1 {
        margin-bottom: 24px;
      }
      .aboutus_h2 {
        font-weight: 290;
        font-size: 34px;
        color: #222222;
        line-height: 34px;
      }
    }

    .banner_content_prow {
      display: flex;
      margin-top: 12px;
      max-width: 60%;

      .banner_content_prow_dot_outer {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .banner_content_prow_dot {
        min-width: 5px;
        height: 5px;
        background: #006c50;
        display: block;
        border-radius: 50%;
      }
      .banner_content_p3 {
        font-weight: 290;
        font-size: 19px;
        color: #222222;
        line-height: 27px;
        margin-left: 5px;
        letter-spacing: 1px;
        // opacity: 0.85;
      }
    }

    .banner_content_btn {
      margin-top: calc(41px - 6px);
      background: #006c50;
      border-radius: 50px;
      border: 6px solid red;
      padding: 15px 49px 15px 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      line-height: 18px;
      background-clip: padding-box;
    }

    .swiper_pagination {
      margin-top: 22px;
    }
    .swiper-pagination-bullet {
      margin-right: 7px;
      background: #006c50;
    }
    .swiper-pagination-bullet-active {
      background: rgba(0, 108, 80, 1);
    }
  }
  .swiper-slide {
    img {
      width: 100%;
      vertical-align: middle;
      object-fit: cover;
      height: 628px;
    }
  }
}
</style>