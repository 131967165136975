<template>
  <div>
    <div class="preview" v-html="localContent"></div>
  </div>
</template>

<script>
import { BASE_URL } from "@/config/index.js";
export default {
  props: {
    // 传过来的html片段
    content: {
      default: "",
    },
  },
  // 监听一个数据的变化
  watch: {
    content: {
      handler(newVal, oldVal) {
        // 数据发生变化时执行的操作
        this.localContent = this.$commonJs.formatEmbed(newVal);
        // console.log(this.localContent);
      },
      deep: true, // 是否深度监听
      immediate: true, // 是否在组件创建时立即执行回调函数
    },
  },
  data() {
    return {
      BASE_URL: BASE_URL,
      localContent: "",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.preview {
  /* min-height: 200px; */
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
}

::v-deep img {
  /* width: 100%; */
  max-width: 90vw;
}
</style>