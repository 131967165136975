<template>
  <div style="position: relative; top: 66px">
    <div class="common_top"></div>
    <div v-if="showDetail" class="news_detail">
      <div class="max_width_1200">
        <div class="news_detail_top">
          <!-- 首页 > -->
          <!-- <p>您的位置： {{ "客户案例" }} > {{ info.title }}</p> -->
          <p>
            您的位置：<span style="cursor: pointer" @click="toHome()"
              >首页 ></span
            >
            <span style="cursor: pointer" @click="toNews()"
              >{{ "客户案例" }} ></span
            >
            {{ info.title }}
          </p>
        </div>
        <div class="news_detail_card">
          <p class="news_detail_card_title">{{ info.title }}</p>
          <div class="news_detail_card_rich">
            <RichEditer :content="info.content"> </RichEditer>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="wx_detail">
      <div class="max_width_1200">
        <iframe id="iFrame" width="100%"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { getGroupInfoDetail, getBrandDetail } from "@/network/public";

import axios from "axios";

export default {
  name: "SecurityNewsDetail",

  data() {
    return {
      showDetail: true,
      info: {},
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0);
      let queryParams = this.$route.query;
      if (queryParams.id) {
        if (queryParams.from == "group") {
          this.getInfo(queryParams.id);
        } else if (queryParams.from == "brand") {
          this.getBrandInfo(queryParams.id);
        }
        if (queryParams.lastPage) {
        }
      }
    },
  },
  mounted() {
    let queryParams = this.$route.query;
    if (queryParams.id) {
      if (queryParams.from == "group") {
        this.getInfo(queryParams.id);
      } else if (queryParams.from == "brand") {
        this.getBrandInfo(queryParams.id);
      }
      if (queryParams.lastPage) {
      }
    }
    window.scrollTo(0, 0);
  },

  methods: {
    toHome() {
      this.$router.push("/");
    },
    toNews() {
      this.$router.push("/customers");
    },
    getInfo(id) {
      getGroupInfoDetail({
        id: id,
      })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            this.info = res.data;
          }
        })
        .catch((e) => {});
    },
    getBrandInfo(id) {
      getBrandDetail({
        id: id,
      })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            this.info = res.data;
          }
        })
        .catch((e) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.news_detail {
  background: #f5f6fa;
  padding: 38px 0 calc(97px + 40px) 0;
  .news_detail_top {
    p {
      font-weight: 400;
      font-size: 12px;
      color: #8d9296;
      line-height: 12px;
    }
  }
  .news_detail_card {
    margin-top: 17px;
    width: 100%;
    background: #ffffff;
    border-radius: 12px 12px 12px 12px;
    padding: 59px 67px;

    &_title {
      font-weight: 700;
      font-size: 26px;
      color: #222222;
      line-height: 26px;
      text-align: center;
    }
    &_rich {
      margin-top: 65px;
    }
  }
}
</style>