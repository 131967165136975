import axios from "axios";
import axiosRetry from 'axios-retry';
import {
	BASE_URL
} from '@/config/index'

export function request(config) {
	const instance = axios.create({
		baseURL: BASE_URL,
		timeout: 20000
	})
	axiosRetry(instance, {
		retries: 3,
		retryDelay: (retryCount) => {
			return retryCount * 1000;
		}
	});
	instance.interceptors.request.use(config => {
		return config
	}, error => {
		return Promise.reject(error)
	});

	instance.interceptors.response.use(config => {
		return config.data;
	}, error => {
		return Promise.reject(error)
	})

	return instance(config)
}