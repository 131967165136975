import router from '../router'
import {
	BASE_URL, RICH_URL
} from '@/config/index.js'

// 路由跳转
export function changeView(url, queryParams) {
	return router.push({
		path: url,
		query: queryParams,
	})
}
//处理图片链接
export function formatImgSrc(imageUrl) {
	if (!imageUrl) {
		return imageUrl
	}
	if (imageUrl.indexOf("http://") != -1 || imageUrl.indexOf("https://") != -1 || imageUrl.indexOf("/img/") != -1) {
		return imageUrl
	} else {
		return BASE_URL + imageUrl
	}
}

export function formatImgSrc2(imageUrl) {
	console.log("formatImgSrc", imageUrl)
	if (!imageUrl) {
		return imageUrl
	}
	if (imageUrl.indexOf("http://") != -1 || imageUrl.indexOf("https://") != -1 || imageUrl.indexOf("/img/") != -1) {
		return imageUrl
	} else {
		return BASE_URL + imageUrl
	}
}

//时间格式化
export function formatTimestamp(timestamp) {
	var date = new Date(timestamp * 1000);
	var year = date.getFullYear();
	var month = addLeadingZero(date.getMonth() + 1);
	var day = addLeadingZero(date.getDate());
	var hours = addLeadingZero(date.getHours());
	var minutes = addLeadingZero(date.getMinutes());
	var seconds = addLeadingZero(date.getSeconds());
	return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
}
// 在获取这些值之后，我们使用addLeadingZero函数来确保每个值都有两位数的格式。该函数检查数字是否小于10，如果是，则在数字前面添加一个零
function addLeadingZero(number) {
	return number < 10 ? '0' + number : number;
}

export function getMonth(timestamp) {
	var date = new Date(timestamp * 1000);
	var month = addLeadingZero(date.getMonth() + 1);
	var day = addLeadingZero(date.getDate());
	return month + '-' + day;
}

export function getYear(timestamp) {
	var date = new Date(timestamp * 1000);
	var year = date.getFullYear();
	return year;
}

// 处理富文本中的图片增加地址
export function formatRichTextImg(content) {
	if (!content) {
		return content
	}
	var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
	var s = content.match(b); // 取到所有img标签 放到数组 s里面
	if (!s) {
		return formatRichTextVideo(content)
	}
	for (var i = 0; i < s.length; i++) {
		var srcImg = s[i].replace(b, "$1"); //取src面的内容
		if (
			srcImg.slice(0, 4) == "http" ||
			srcImg.slice(0, 5) == "https"
		) {
			//若src前4位置或者前5位是http、https则不做任何修改
			//console.log("不做任何修改");
		} else {
			//修改富文本字符串内容 img标签src 相对路径改为绝对路径
			content = content.replace(
				new RegExp(srcImg, "g"),
				RICH_URL + srcImg
			);
		}
	}
	return formatRichTextVideo(content)
}
// 给video标签加链接
export function formatRichTextVideo(content) {
	if (!content) {
		return content
	}
	var b = /<video [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
	var s = content.match(b); // 取到所有img标签 放到数组 s里面
	if (!s) {
		return content
	}
	for (var i = 0; i < s.length; i++) {
		var srcImg = s[i].replace(b, "$1"); //取src面的内容
		if (
			srcImg.slice(0, 4) == "http" ||
			srcImg.slice(0, 5) == "https"
		) {
			//若src前4位置或者前5位是http、https则不做任何修改
			// console.log("不做任何修改");
		} else {
			//修改富文本字符串内容 img标签src 相对路径改为绝对路径
			content = content.replace(
				new RegExp(srcImg, "g"),
				RICH_URL + srcImg
			);
		}
	}
	return content
}

export function isValidChinesePhoneNumber(phoneNumber) {
	const regex = /^1[3-9]\d{9}$/;
	return regex.test(phoneNumber);
}


export function formatEmbed(content) {
	if (!content) {
		return content
	}
	// <embed\s[^>]*\/?>
	content = content.replace(
		new RegExp("<embed", "g"),
		"<iframe"
	);
	content = content.replace(
		new RegExp("embed>", "g"),
		"iframe>"
	);
	return formatRichTextImgForUeditor(content)
}

// 修改为Ueditor后处理富文本中的图片增加地址
export function formatRichTextImgForUeditor(content) {
	if (!content) {
		return content
	}
	var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
	var s = content.match(b); // 取到所有img标签 放到数组 s里面
	if (!s) {
		return formatRichTextVideoForUeditor(content)
	}
	for (var i = 0; i < s.length; i++) {
		var srcImg = s[i].replace(b, "$1"); //取src面的内容
		if (
			srcImg.slice(0, 4) == "http" ||
			srcImg.slice(0, 5) == "https"
		) {
			//若src前4位置或者前5位是http、https则不做任何修改
			//console.log("不做任何修改");
		} else {
			//修改富文本字符串内容 img标签src 相对路径改为绝对路径
			content = content.replace(
				new RegExp(srcImg, "g"),
				RICH_URL + srcImg
			);
		}
	}
	return formatRichTextVideoForUeditor(content)
}
// 修改为Ueditor后给video标签加链接
function formatRichTextVideoForUeditor(content) {
	if (!content) {
		return content
	}
	var b = /<video [^>]*src=['"]([^'"]+)[^>]*>/g;   // img 标签取src里面内容的正则
	var s = content.match(b); // 取到所有img标签 放到数组 s里面
	if (!s) {
		return content
	}
	for (var i = 0; i < s.length; i++) {
		var srcImg = s[i].replace(b, "$1"); //取src面的内容
		if (
			srcImg.slice(0, 4) == "http" ||
			srcImg.slice(0, 5) == "https"
		) {
			//若src前4位置或者前5位是http、https则不做任何修改
			// console.log("不做任何修改");
		} else {
			//修改富文本字符串内容 img标签src 相对路径改为绝对路径
			content = content.replace(
				new RegExp(srcImg, "g"),
				RICH_URL + srcImg
			);
		}
	}
	return content
}
export function test() {
	console.log("test")
}