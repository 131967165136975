<template>
  <div>
    <!-- -->
    <div class="common_top"></div>
    <Level2Banner
      v-if="info.contents"
      :title="info.title"
      :bannerImage="info.contents.banner"
      :outerUrl="info.contents.url"
      :btnText="info.btn_text"
      :level2Title="info.contents.desc"
      :from="'aboutus'"
    ></Level2Banner>
    <div class="aboutus">
      <div v-if="allInfo.desc" id="intro" class="aboutus_block1 max_width_1200">
        <div class="aboutus_block1_title">
          <span>{{ allInfo.desc.title }}</span>
        </div>
        <div class="aboutus_block1_rich">
          <RichEditer :content="allInfo.desc.content"> </RichEditer>
        </div>
      </div>
      <!-- 产品定位 -->
      <div
        v-if="allInfo.goods"
        id="product"
        class="aboutus_block2 max_width_1200"
      >
        <div class="aboutus_block2_title">
          <span>{{ allInfo.goods.title }}</span>
        </div>
        <img
          :src="$commonJs.formatImgSrc(allInfo.goods.contents.image)"
          alt=""
        />
      </div>
      <!-- 核心价值 -->
      <div v-if="allInfo.core_values" id="core" class="aboutus_block3">
        <div class="max_width_1200">
          <div class="aboutus_block2_title">
            <span>{{ allInfo.core_values.info.title }}</span>
          </div>
          <div class="aboutus_block3_content">
            <template v-for="(item, index) in allInfo.core_values.list">
              <div :key="index" class="aboutus_block3_item">
                <div class="aboutus_block3_item_no">
                  <div class="aboutus_block3_item_icon">
                    <img :src="$commonJs.formatImgSrc(item.icon)" alt="" />
                  </div>
                  <div class="aboutus_block3_item_line"></div>
                  <p>{{ item.title }}</p>
                </div>
                <div class="aboutus_block3_item_green">
                  <div class="aboutus_block3_item_green_top">
                    <img :src="$commonJs.formatImgSrc(item.image)" alt="" />
                  </div>
                  <div class="aboutus_block3_item_green_bot">
                    <p class="aboutus_block3_item_green_bot_title">
                      {{ item.title }}
                    </p>
                    <div class="aboutus_block3_item_green_bot_line"></div>
                    <p class="aboutus_block3_item_green_bot_desc">
                      {{ item.desc }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        id="certificate"
        v-if="allInfo.qualifications"
        class="aboutus_block4 max_width_1200"
      >
        <div class="aboutus_block2_title">
          <span>{{ allInfo.qualifications.info.title }}</span>
        </div>
        <div class="aboutus_block4_swiper">
          <CertificateSwiper
            :list="allInfo.qualifications.list"
          ></CertificateSwiper>
        </div>
      </div>
      <div v-if="allInfo.covering_cities" class="aboutus_block5">
        <div class="max_width_1200">
          <div class="aboutus_block2_title">
            <span>{{ allInfo.covering_cities.title }}</span>
          </div>
          <div
            v-if="allInfo.covering_cities.contents"
            class="aboutus_block5_numbers"
          >
            <template
              v-for="(item, index) in allInfo.covering_cities.contents.content"
            >
              <div :key="index" style="display: flex; align-items: center">
                <div class="aboutus_block5_numbers_column">
                  <div class="aboutus_block5_numbers_column_top">
                    <span class="aboutus_block5_numbers_column_top_span1">{{
                      item.number
                    }}</span
                    ><span
                      :style="index == 2 ? 'font-size: 38px' : ''"
                      class="aboutus_block5_numbers_column_top_span2"
                      >{{ item.unit }}</span
                    >
                  </div>
                  <span class="aboutus_block5_numbers_column_desc">{{
                    item.title
                  }}</span>
                </div>
                <p
                  v-if="
                    index != allInfo.covering_cities.contents.content.length - 1
                  "
                  class="aboutus_block5_numbers_line"
                ></p>
              </div>
            </template>
          </div>
          <div class="aboutus_block5_img">
            <img
              :src="
                $commonJs.formatImgSrc(allInfo.covering_cities.contents.image)
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CertificateSwiper from "../components/certificateSwiper.vue";
import { getAboutUs } from "@/network/public";

export default {
  name: "SecurityAboutUs",
  components: {
    CertificateSwiper,
  },
  data() {
    return {
      info: {},
      allInfo: {},
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      this.checkAchor();
    },
  },
  mounted() {
    this.getInfo();
  },

  methods: {
    getInfo() {
      getAboutUs()
        .then((res) => {
          if (res.status == 200) {
            this.info = res.data.info;
            this.allInfo = res.data;
            this.checkAchor();
            console.log(res.data);
          }
        })
        .catch((e) => {});
    },
    checkAchor() {
      let that = this;
      let queryParams = this.$route.query;
      if (queryParams.id) {
        this.$nextTick(() => {
          that.achor(queryParams.id);
        });
      }
    },
    achor(key) {
      console.log("key", key, document.querySelector(`#${key}`));
      // row.key   为对应的id名
      document.querySelector(`#${key}`).scrollIntoView({
        behavior: "smooth",
        // // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
        // block: "start",
        // // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
        // inline: "nearest",
        // // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.max_width_1200 {
  max-width: 1450px;
  margin: 0 auto;
}
.aboutus_block1 {
  padding: 60px;
  padding-top: 80px;
  max-width: 1200px;
  margin: 0 auto;
  // padding-bottom: 0;
  .aboutus_block1_title {
    font-weight: bold;
    font-size: 30px;
    color: #222222;
    line-height: 30px;
    text-align: center;
  }
  .aboutus_block1_rich {
    margin-top: 20px;
  }
}
.aboutus_block2_title {
  font-weight: 400;
  font-size: 30px;
  color: #222222;
  line-height: 30px;
  text-align: center;
}
.aboutus_block2 {
  padding-top: 80px;

  img {
    margin-top: 57px;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
.aboutus_block3 {
  margin-top: 82px;
  padding-top: 75px;
  background: #f8f8f8;
  padding-bottom: 100px;

  .aboutus_block3_content {
    padding-top: 63px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 6px;
    .aboutus_block3_item {
      width: 100%;
      // background: #f7f7f7;
      border-radius: 12px 12px 12px 12px;
      padding: 44px;
      align-self: stretch;
      box-sizing: border-box;
      height: 482px;
      background: #ffffff;
      .aboutus_block3_item_no {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        transition: opacity 0.5s ease;

        .aboutus_block3_item_icon {
          img {
            width: 120px;
            height: 120px;
            object-fit: contain;
          }
        }
        .aboutus_block3_item_line {
          width: 44px;
          height: 1px;
          background: rgba(0, 108, 80, 1);
          display: block;
          margin-top: 40px;
          margin-bottom: 25px;
        }
        p {
          font-weight: 400;
          font-size: 22px;
          line-height: 22px;
          color: rgba(0, 108, 80, 1);
        }
      }
      .aboutus_block3_item_green {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // display: none;
        transition: opacity 0.5s ease;
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: -1;
        height: 0;

        .aboutus_block3_item_green_top {
          width: 100%;
          height: 152px;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .aboutus_block3_item_green_bot {
          padding-top: 25px;
          &_title {
            font-weight: 400;
            font-size: 22px;
            color: #ffffff;
            line-height: 22px;
          }
          &_line {
            width: 44px;
            height: 1px;
            background: #ffffff;
            display: block;
            margin-top: 55px;
          }
          &_desc {
            // font-weight: 290;
            font-size: 16px;
            color: #ffffff;
            line-height: 22px;
            margin-top: 28px;
            letter-spacing: 1px;
          }
        }
      }
    }
    .aboutus_block3_item:hover {
      background: #006c50;
      .aboutus_block3_item_no {
        display: none;
        opacity: 0;
        position: absolute;
        left: 0;
        z-index: -1;
      }
      .aboutus_block3_item_green {
        display: flex;
        opacity: 1;
        position: relative;
        left: 0;
        z-index: 1;
        height: auto;
      }
    }
  }
}
.aboutus_block4 {
  padding-top: 86px;
  max-width: 1280px;
  margin: 0 auto;
  .aboutus_block4_swiper {
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
.aboutus_block5 {
  padding-bottom: 80px;
  // max-width: 1200px;
  // margin: 0 auto;
  padding-top: 80px;
  background: #f8f8f8;
  position: relative;
  top: 30px;
  .aboutus_block5_numbers {
    margin-top: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    .aboutus_block5_numbers_column {
      // margin-right: 200px;
      &_top {
        &_span1 {
          font-weight: 1000;
          font-size: 82px;
          color: #006c50;
          line-height: 82px;
          font-family: AcuminVariableConcept;
          letter-spacing: 2px;
        }
        &_span2 {
          font-weight: 400;
          font-size: 58px;
          color: #006c50;
          line-height: 58px;
          margin-left: 5px;
        }
      }
      &_desc {
        // margin-top: 13px;
        font-weight: 400;
        font-size: 18px;
        color: #8c9195;
        line-height: 31px;
      }
    }
    .aboutus_block5_numbers_column:last-child {
      margin-right: 0;
    }
  }
  .aboutus_block5_img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 66px;
    img {
      width: 815px;
      // height: 670.41px;
      object-fit: contain;
    }
  }
  .aboutus_block5_numbers_line {
    width: 0.25px;
    height: 78px;
    background: #999fb3;
    opacity: 0.25;
    margin: 0 100px;
  }
}
</style>