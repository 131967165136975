<template>
  <div>
    <div class="common_top"></div>
    <Level2Banner
      v-if="info.contents"
      :title="info.title"
      :descList="info.contents.desc"
      :bannerImage="info.contents.image"
      :outerUrl="info.contents.url"
      :btnText="info.btn_text"
      :smallTitle="info.title_little"
      :level2Title="info.title_two"
    ></Level2Banner>
    <div v-if="info.contents" class="solutions">
      <div v-if="info.contents.first_title" class="solutions_block1">
        <div class="max_width_1200" style="max-width: 1095px">
          <p class="solutions_block1_title">{{ info.contents.first_title }}</p>
          <div class="solutions_block1_img">
            <img
              :src="$commonJs.formatImgSrc(info.contents.first_image)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-if="info.contents.second_title" class="solutions_block2">
        <div class="max_width_1200">
          <p class="solutions_block2_title">{{ info.contents.second_title }}</p>
          <template v-for="(item, index) in secondList">
            <div :key="index" class="solutions_block2_contentimgs_outer">
              <div class="solutions_block2_contentimgs">
                <div
                  v-for="(childItem, childIndex) in item.list"
                  :key="childIndex"
                  class="solutions_block2_contentimgs_item"
                >
                  <img :src="$commonJs.formatImgSrc(childItem.icon)" alt="" />
                </div>
              </div>
              <div class="solutions_block2_contents">
                <div
                  v-for="(childItem, childIndex) in item.list"
                  :key="childIndex"
                  class="solutions_block2_contents_column"
                >
                  <span>{{ childItem.title }}</span>
                  <img src="../assets/img/diandiantu.png" alt="" />
                  <p>{{ childItem.desc }}</p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="info.contents.third_title" class="solutions_block3">
        <div class="max_width_1200">
          <p class="solutions_block_title">{{ info.contents.third_title }}</p>
          <div class="solutions_block3_content">
            <template v-for="(item, index) in info.contents.third">
              <div :key="index" class="solutions_block3_content_column">
                <div class="solutions_block3_content_column_top">
                  <img :src="$commonJs.formatImgSrc(item.image)" alt="" />
                </div>
                <div class="solutions_block3_content_column_bot">
                  <div class="solutions_block3_content_column_bot_row">
                    <span
                      class="solutions_block3_content_column_bot_row_left"
                      >{{ item.befor_title }}</span
                    >
                    <span
                      class="solutions_block3_content_column_bot_row_right"
                      >{{ item.befor }}</span
                    >
                  </div>
                  <div class="solutions_block3_content_column_bot_row">
                    <span
                      class="solutions_block3_content_column_bot_row_left"
                      >{{ item.after_title }}</span
                    >
                    <span class="solutions_block3_content_column_bot_row_right">
                      {{ item.after }}</span
                    >
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="info.contents.fourth_title" class="solutions_block4">
        <div class="max_width_1200" style="max-width: 1087.73px">
          <p class="solutions_block_title">{{ info.contents.fourth_title }}</p>
          <div class="solutions_block4_img">
            <img
              :src="$commonJs.formatImgSrc(info.contents.fourth_image)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-if="info.contents.fifth_title" class="solutions_block5">
        <div class="max_width_1200" style="max-width: 1087.73px">
          <p class="solutions_block_title">{{ info.contents.fifth_title }}</p>
          <div class="solutions_block5_cards">
            <template v-for="(item, index) in info.contents.fifth">
              <div :key="index" class="solutions_block5_cards_item">
                <div class="solutions_block5_cards_item_top">
                  <img :src="$commonJs.formatImgSrc(item.image)" alt="" />
                </div>
                <div class="solutions_block5_cards_item_bot">
                  <p class="solutions_block5_cards_item_bot_title">
                    {{ item.title }}
                  </p>
                  <p class="solutions_block5_cards_item_bot_desc">
                    {{ item.desc }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="info.contents.sixth_title" class="solutions_block4">
        <div class="max_width_1200">
          <p class="solutions_block_title">{{ info.contents.sixth_title }}</p>
          <div class="solutions_block_img">
            <img
              :src="$commonJs.formatImgSrc(info.contents.sixth_image)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-if="info.contents.seventh_title" class="solutions_block6">
        <div class="max_width_1200" style="max-width: 1120px">
          <template>
            <p class="solutions_block_title">
              {{ info.contents.seventh_title }}
            </p>
            <div class="solutions_block6_row">
              <div class="solutions_block6_row_pbox">
                <p class="solutions_block6_row_pbox_title">
                  {{ info.contents.seventh.title }}
                </p>
                <div
                  v-for="(item, index) in info.contents.seventh.desc"
                  :key="index"
                  class="solutions_block6_row_pbox_descs"
                >
                  <span class="solutions_block6_row_pbox_descs_dot"></span>
                  <p>{{ item.title }}</p>
                </div>
              </div>
              <div class="solutions_block6_row_imgbox">
                <img
                  :src="$commonJs.formatImgSrc(info.contents.seventh.image)"
                  alt=""
                />
              </div>
            </div>
          </template>
          <div v-if="info.contents.eighth_title" class="solutions_block6_bot">
            <p class="solutions_block_title">
              {{ info.contents.eighth_title }}
            </p>
            <div class="solutions_block6_row">
              <div class="solutions_block6_row_imgbox">
                <img
                  :src="$commonJs.formatImgSrc(info.contents.eighth.image)"
                  alt=""
                />
              </div>
              <div class="solutions_block6_row_pbox">
                <p class="solutions_block6_row_pbox_title">
                  {{ info.contents.eighth.title }}
                </p>
                <div class="solutions_block6_row_pbox_descsbox">
                  <template v-for="(item, index) in info.contents.eighth.desc">
                    <div
                      :key="index"
                      v-if="index < 15"
                      class="solutions_block6_row_pbox_descs"
                    >
                      <span class="solutions_block6_row_pbox_descs_dot"></span>
                      <p v-if="index < 14">{{ item.title }}</p>
                      <p v-else>...</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProgrammeInfo } from "@/network/public";
export default {
  name: "SecuritySolutions",
  data() {
    return {
      info: {},
      secondList: [],
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      let queryParams = this.$route.query;
      if (queryParams.id) {
        this.getInfo(queryParams.id);
      } else {
        this.getInfo(1);
      }
    },
  },
  mounted() {
    let queryParams = this.$route.query;
    if (queryParams.id) {
      this.getInfo(queryParams.id);
    } else {
      this.getInfo(1);
    }
  },

  methods: {
    getInfo(id) {
      getProgrammeInfo({
        id: id,
      })
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            this.info = res.data;
            this.info.contents.second;
            if (this.info.contents.second.length > 0) {
              this.secondList = [];
              const groupedArray = this.info.contents.second.reduce(
                (acc, current, index) => {
                  const chunkIndex = Math.floor(index / 6); // 计算当前元素应该属于哪个分组

                  if (!acc[chunkIndex]) {
                    acc[chunkIndex] = { list: [] }; // 如果这个分组还没有在结果数组中出现，则初始化它
                  }

                  acc[chunkIndex].list.push(current); // 将当前元素添加到对应的分组中

                  if ((index + 1) % 6 === 0) {
                    // 如果当前元素是某个分组的最后一个元素（即每组的第6个元素），
                    // 可以选择在这里做一些额外的处理，但在这个例子中我们不需要
                  }

                  return acc;
                },
                []
              ); // 使用空数组作为累积器的初始值
              this.secondList = groupedArray;
            }
          }
        })
        .catch((e) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.solutions {
  .solutions_block1 {
    padding: 88px 0 85px 0;

    &_title {
      font-weight: 700;
      font-size: 30px;
      color: #222222;
      line-height: 30px;
      text-align: center;
    }
    .solutions_block1_img {
      display: flex;
      margin-top: 47px;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
  .solutions_block2 {
    padding: 84px 0 80px 0;
    background: #f7f7f7;
    &_title {
      font-weight: 400;
      font-size: 30px;
      color: #222222;
      line-height: 30px;
      text-align: center;
    }
    .solutions_block2_contentimgs_outer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 44px;
    }
    .solutions_block2_contentimgs {
      background-image: url("../assets/img/sigequan.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 259.97px;
      width: 923.16px;

      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .solutions_block2_contentimgs_item {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }
      .solutions_block2_contentimgs_item:first-child {
        margin-left: 25px;
      }
      .solutions_block2_contentimgs_item:last-child {
        margin-right: 25px;
      }
    }
    .solutions_block2_contents {
      margin-top: 53px;
      width: 923.16px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 0 25px;
      .solutions_block2_contents_column {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 5px;
        padding: 0 20px;

        span {
          font-weight: 700;
          font-size: 24px;
          color: #222222;
          line-height: 24px;
        }
        img {
          width: 75px;
          height: 10.41px;
          margin-top: 20px;
        }
        p {
          margin-top: 39px;
          font-weight: 400;
          font-size: 18px;
          color: #6c6c6c;
          line-height: 26px;
          letter-spacing: 1px;
          text-align: justify;
          position: relative;
          left: 0.14rem;
        }
      }
    }
  }
  .solutions_block_title {
    font-weight: 400;
    font-size: 30px;
    color: #222222;
    line-height: 30px;
    text-align: center;
    white-space: nowrap;
  }
  .solutions_block3 {
    padding: 80px 0 70px 0;
    .solutions_block3_title {
      font-weight: 400;
      font-size: 30px;
      color: #222222;
      line-height: 30px;
      text-align: center;
    }
    .solutions_block3_content {
      margin-top: 52px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 22px;
      .solutions_block3_content_column {
        display: flex;
        flex-direction: column;
        &_top {
          border-radius: 6px;
          overflow: hidden;
          box-shadow: 5px 5px 16px 5px rgba(0, 0, 0, 0.1216);
          img {
            width: 100%;
            height: 178px;
            vertical-align: middle;
          }
        }
        &_bot {
          margin-top: 28px;
          &_row {
            margin-top: 8px;
            display: flex;
            align-items: center;
            &_left {
              background: #006c50;
              border-radius: 4px 4px 4px 4px;
              font-weight: 700;
              font-size: 14px;
              color: #ffffff;
              line-height: 14px;
              padding: 4px;
            }
            &_right {
              margin-left: 10px;
              font-weight: 400;
              font-size: 14px;
              color: rgba(108, 108, 108, 1);
              line-height: 14px;
            }
          }
        }
      }
    }
  }

  .solutions_block4 {
    background: #f7f7f7;
    padding: 85px 0 87px 0;
    .solutions_block4_img {
      margin-top: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 1087.73px;
        height: 332.43px;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
  .solutions_block5 {
    padding: 74px 0 86px 0;
    .solutions_block5_cards {
      margin-top: 46px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 19px;
      .solutions_block5_cards_item {
        background: #ffffff;
        box-shadow: -3px 15px 23px 0px rgba(149, 149, 149, 0.1608);
        border-radius: 18px;
        overflow: hidden;
        .solutions_block5_cards_item_top {
          border-radius: 18px 18px 0 0;
          height: 157px;
          overflow: hidden;

          img {
            width: 100%;
            height: 157px;
            object-fit: cover;
            transition: all 0.3s ease-in-out;
          }
        }
        .solutions_block5_cards_item_top:hover {
          img {
            transform: scale(1.1);
          }
        }
        &_bot {
          padding: 25px;
          &_title {
            font-weight: 400;
            font-size: 20px;
            color: #222222;
            line-height: 20px;
          }
          &_desc {
            // font-weight: 290;
            font-size: 14px;
            color: #666666;
            line-height: 19px;
            margin-top: 20px;
            letter-spacing: 1.5px;
          }
        }
      }
    }
  }

  .solutions_block_img {
    margin-top: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .solutions_block6 {
    padding: 69px 80px 170px 80px;
    .solutions_block6_row {
      margin-top: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      .solutions_block6_row_pbox {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &_title {
          font-weight: 700;
          font-size: 26px;
          color: #222222;
          line-height: 26px;
          margin-bottom: 12px;
        }
        &_descs {
          display: flex;
          align-items: center;
          margin-top: 10px;
          width: 100%;

          &_dot {
            width: 7px;
            height: 7px;
            background: #006c50;
            border-radius: 50%;
            display: block;
          }
          p {
            margin-left: 15px;
            font-weight: 400;
            font-size: 18px;
            color: #6b6866;
            line-height: 24px;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .solutions_block6_row_imgbox {
        flex: 1;
        img {
          max-width: 500px;
          height: auto;
          object-fit: contain;
        }
      }
    }
    .solutions_block6_bot {
      margin-top: 101px;
      .solutions_block6_row_pbox {
        margin-left: 144px;
        .solutions_block6_row_pbox_descsbox {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 46px;
        }
      }
    }
  }
}
</style>