<template>
  <el-row class="tab_swiper_outer">
    <el-col :span="1">
      <div
        :style="list.length <= slidesPerView ? 'visibility: hidden;' : ''"
        @click="slidePrev"
        class="tab-prev"
      >
        <img class="icon" src="@/assets/img/weixuanzhongzuo.png" alt="" />
        <img class="active_icon" src="@/assets/img/xuanzhongzuo.png" alt="" />
      </div>
    </el-col>
    <el-col :span="22">
      <swiper
        @slideChange="slideChange"
        ref="tabSwiper"
        class="tab_swiper"
        :options="swiperOption"
      >
        <swiper-slide v-for="(item, index) in list" :key="index">
          <div
            @click="tabItemClick(item, index)"
            class="tab_swiper_tabs_item"
            :class="index == tabIndex ? 'tab_swiper_tabs_item_active' : ''"
          >
            <template v-if="from == 'apply'">
              <img
                v-if="index == tabIndex"
                :src="$commonJs.formatImgSrc(item.icon_select)"
                alt=""
              />
              <img v-else :src="$commonJs.formatImgSrc(item.ion)" alt="" />
              <span>{{ item.title }}</span>
            </template>
            <template v-else>
              <img
                v-if="index == tabIndex"
                :src="$commonJs.formatImgSrc(item.sel_icon)"
                alt=""
              />
              <img v-else :src="$commonJs.formatImgSrc(item.icon)" alt="" />
              <span>{{ item.title }}</span>
            </template>
          </div>
        </swiper-slide>
      </swiper>
    </el-col>
    <el-col :span="1">
      <div
        :style="list.length <= slidesPerView ? 'visibility: hidden;' : ''"
        @click="slideNext"
        class="tab-next"
      >
        <img class="icon" src="@/assets/img/weixuanzhongyou.png" alt="" />
        <img
          class="active_icon"
          style="transform: rotate(180deg)"
          src="../assets/img/xuanzhongzuo.png"
          alt=""
        />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { BASE_URL } from "@/config/index.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "swiper-example-loop-group",
  title: "Loop mode with multiple slides per group",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    slidesPerView: {
      type: Number,
      default: 5,
    },
    from: {
      type: String,
    },
  },
  created() {},
  data() {
    return {
      BASE_URL: BASE_URL,
      tabIndex: 0,
      swiperOption: {
        autoplay: false, // 自动轮播
        speed: 600, // 轮播速度
        slidesPerView: 5,
        spaceBetween: 0,
        slidesPerGroup: 1,
        loop: false,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".tab-next",
          prevEl: ".tab-prev",
        },
        slideToClickedSlide: true,
        // centeredSlides: true,
      },
    };
  },
  watch: {
    tabIndex: {
      handler(newV, oldV) {
        this.$emit("tabChange", newV);
      },
    },
  },
  computed: {
    swiper() {
      return this.$refs.tabSwiper.$swiper;
    },
  },
  mounted() {
    this.swiper.params.slidesPerView = this.slidesPerView;
    this.$forceUpdate();
  },
  methods: {
    slideChange() {
      this.tabIndex = this.swiper.realIndex;
    },
    tabItemClick(item, index) {
      this.tabIndex = index;
    },
    slidePrev() {
      if (this.tabIndex > 0) {
        this.tabIndex--;
      }
    },
    slideNext() {
      if (this.tabIndex < this.list.length - 1) {
        this.tabIndex++;
      }
    },
  },
};
</script>

<style lang="less" >
.tab_swiper_outer {
  position: relative;
  // display: flex;
  // align-items: center;
  .tab-prev {
    visibility: hidden;
  }
  .tab-next {
    visibility: hidden;
  }
}
.tab_swiper {
  box-sizing: border-box;
  position: relative;
  .tab_swiper_tabs_item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 17px;
    border-bottom: 2px solid transparent;
    cursor: pointer;

    img {
      width: 30px;
      height: 26px;
      object-fit: contain;
    }
    span {
      font-weight: 700;
      font-size: 16px;
      color: #666666;
      line-height: 26px;
      margin-left: 8px;
      white-space: nowrap;
      color: rgba(102, 102, 102, 0.8);
    }
  }
  .tab_swiper_tabs_item_active {
    border-bottom: 2px solid rgba(0, 109, 80, 1);
    span {
      color: rgba(34, 34, 34, 1);
    }
  }
}
.tab-prev {
  cursor: pointer;
  z-index: 1;
  img {
    width: 40px;
    height: 40px;
  }
  .icon {
    display: block;
  }
  .active_icon {
    display: none;
  }
}

.tab-next {
  cursor: pointer;
  z-index: 1;
  .icon {
    display: block;
  }
  .active_icon {
    display: none;
  }
  img {
    width: 40px;
    height: 40px;
  }
}
.tab-prev:hover {
  .icon {
    display: none;
  }
  .active_icon {
    display: block;
  }
}
.tab-next:hover {
  .icon {
    display: none;
  }
  .active_icon {
    display: block;
  }
}
.tab_swiper_outer:hover {
  .tab-prev {
    visibility: visible;
  }
  .tab-next {
    visibility: visible;
  }
}
</style>