<template>
  <div id="app">
    <keep-alive>
      <Header id="header"></Header>
    </keep-alive>
    <router-view />
    <keep-alive>
      <Footer></Footer>
    </keep-alive>
    <RightFix></RightFix>
  </div>
</template>

<script >
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import "swiper/css/swiper.css";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      dialogVisible: true,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.max_width_1200 {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}
.common_top {
  // padding-top: 36px;
}
.common_btn_style {
  transition: all 0.1s ease !important;
  background-clip: padding-box !important;
  border-color: transparent !important;
  border-style: solid !important;
  letter-spacing: 1.2px !important;
}
.common_btn_style:hover {
  border-color: rgba(0, 108, 80, 0.1) !important;
}

button {
  cursor: pointer;
  border-radius: 50%;
}

/* 滚动条样式优化 */
::-webkit-scrollbar {
  /* width: 15px !important; */
}

.el-popup-parent--hidden {
  #header {
    padding-right: 23px;
  }
  .return_top {
    padding-right: 23px;
  }
  .right_fix {
    padding-right: 23px;
  }
}
#app {
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  // top: 30px;
}
</style>
