<template>
  <div id="rightfix">
    <div v-if="footInfo.config" class="right_fix">
      <!-- -->
      <div class="right_fix_item_outer">
        <!-- background: #ffffff; -->
        <div class="right_fix_item" style="border-radius: 8px 8px 0 0">
          <img
            class="kefuicon right_fix_item_icon"
            src="../assets/img/zaixiankefu.png"
            alt=""
          />
          <img
            class="kefuicon right_fix_item_icon_active"
            src="../assets/img/xuanzhongkefu.png"
            alt=""
          />

          <span class="right_fix_item_span" style="margin-top: 3px"
            >在线客服</span
          >
          <!-- color: black -->
          <div class="qrbox qrbox1">
            <img
              :src="$commonJs.formatImgSrc(footInfo.config.online_service)"
              alt=""
            />
            <div class="qrbox_bot">
              <p>
                即刻添加 <br />
                为您提供实时系统解答
              </p>
            </div>
          </div>
        </div>
        <div class="right_fix_item_line"></div>
        <div class="right_fix_item">
          <img
            class="iconimg right_fix_item_icon"
            src="../assets/img/dianhuahei.png"
            alt=""
          />
          <img
            class="iconimg right_fix_item_icon_active"
            src="../assets/img/dianhuahui.png"
            alt=""
          />
          <span class="right_fix_item_span">电话咨询</span>
          <div class="qrbox telbox">
            <p>{{ footInfo.config.online_mobile }}</p>
          </div>
        </div>
        <div class="right_fix_item_line"></div>
        <div class="right_fix_item">
          <img
            class="iconimg right_fix_item_icon"
            src="../assets/img/weixinhei.png"
            alt=""
          />
          <img
            class="iconimg right_fix_item_icon_active"
            src="../assets/img/weixinhui.png"
            alt=""
          />
          <span class="right_fix_item_span">微信咨询</span>
          <div class="qrbox qrbox2">
            <img
              :src="$commonJs.formatImgSrc(footInfo.config.wechat_contact)"
              alt=""
            />
            <div class="qrbox_bot">
              <p>添加企业微信为您提供1对1的专属服务</p>
            </div>
          </div>
        </div>
        <div class="right_fix_item_line"></div>
        <div @click="toRegister" class="right_fix_item register_item">
          <img
            class="iconimg right_fix_item_icon"
            src="../assets/img/zhucehei.png"
            alt=""
          />
          <img
            class="iconimg right_fix_item_icon_active"
            src="../assets/img/zhucehui.png"
            alt=""
          />
          <span class="right_fix_item_span">注册体验</span>
        </div>
      </div>
    </div>
    <FormComponents
      @handlerCancel="handlerCancel"
      :dialogVisible="dialogVisible"
    ></FormComponents>
  </div>
</template>

<script>
import { getFootInfo } from "@/network/public";

export default {
  name: "SecurityRightFix",

  data() {
    return {
      dialogVisible: false,
      footInfo: {},
    };
  },

  mounted() {
    this.getInfo();
  },

  methods: {
    getInfo() {
      getFootInfo()
        .then((res) => {
          if (res.status == 200) {
            this.footInfo = res.data;
          }
        })
        .catch((e) => {});
    },
    toRegister() {
      this.dialogVisible = true;
    },
    handlerCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss">
.right_fix {
  position: fixed;
  right: 40px;
  top: 30vh;
  z-index: 11;

  .right_fix_item_outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    // box-shadow: 2px 3px 8px 0px rgba(217, 217, 217, 0.7412);
    box-shadow: 3px 4px 12px 4px rgba(217, 217, 217, 0.4);
    border-radius: 8px;
    position: relative;

    .right_fix_contact {
      img {
        width: 64px;
        height: 64px;
      }
    }
    .right_fix_item {
      background: #ffffff;
      padding: 11px 7px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      position: relative;

      .qrbox {
        transition: all 0.3s ease;
        position: absolute;
        left: -181px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 163px;
        padding-bottom: 17px;
        background: #ffffff;
        box-shadow: 2px 3px 8px 0px rgba(217, 217, 217, 0.7412);
        border-radius: 6px 6px 6px 6px;
        overflow: hidden;
        height: 0;
        padding: 0;
        img {
          width: 100%;
          height: 163px;
        }
        .qrbox_bot {
          width: 100%;
          background: #ffffff;
          padding: 0px 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            font-weight: 290;
            font-size: 14px;
            color: #222222;
            line-height: 18px;
            text-align: center;
          }
        }
      }
      .qrbox1 {
        bottom: 0;
        // display: none;
      }
      .qrbox2 {
        top: 0;
      }

      .telbox {
        padding: 0;
        width: 164px;
        height: 46px;
        background: #ffffff;
        top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0;
        white-space: nowrap;

        p {
          font-weight: 400;
          font-size: 16px;
          color: #222222;
          line-height: 16px;
        }
      }

      .iconimg {
        width: 18px;
        height: 18px;
      }

      .kefuicon {
        width: 54px;
        height: 42px;
        width: 47px;
        object-fit: contain;
      }

      .right_fix_item_icon {
        display: block;
      }
      .right_fix_item_icon_active {
        display: none;
      }
      .right_fix_item_span {
        font-weight: 290;
        font-size: 12px;
        color: #222222;
        line-height: 12px;
        margin-top: 11px;
      }
    }
    .right_fix_item:hover {
      background: rgba(0, 108, 80, 1);
      .right_fix_item_icon {
        display: none;
      }
      .right_fix_item_icon_active {
        display: block;
      }
      .right_fix_item_span {
        color: #ffffff;
      }

      .qrbox {
        display: flex;
        height: auto;
        padding-bottom: 17px;
      }

      .qrbox1 {
        padding-bottom: 17px;
      }
      .qrbox2 {
        padding-bottom: 17px;
      }

      .telbox {
        padding-bottom: 0;
        height: 46px;
        width: 164px;
      }
    }
    .register_item {
      border-radius: 0 0 8px 8px;
      cursor: pointer;
    }
  }

  .right_fix_item_line {
    width: 29px;
    height: 1px;
    background: #cbcbcb;
    display: block;
  }
}
</style>