<template>
  <div>
    <div class="common_top"></div>
    <Level2Banner
      v-if="info.contents"
      :title="info.title"
      :descList="info.contents.desc"
      :bannerImage="info.contents.image"
      :outerUrl="info.contents.url"
      :btnText="info.btn_text"
    ></Level2Banner>
    <div v-if="info.contents" class="products">
      <div class="productfunc">
        <div class="max_width_1200">
          <p class="productfunc_title">
            {{ info.contents.cptitle }}
            <!-- 产品功能 -->
          </p>
          <div class="productfunc_content">
            <template v-for="(item, index) in info.contents.list">
              <div :key="index" class="productfunc_content_item">
                <div class="productfunc_content_item_left">
                  <img
                    class="rotate_img"
                    :src="$commonJs.formatImgSrc(item.icon)"
                    alt=""
                  />
                </div>
                <div class="productfunc_content_item_right">
                  <p class="productfunc_content_item_right_title">
                    {{ item.title }}
                  </p>
                  <p class="productfunc_content_item_right_desc">
                    {{ item.desc }}
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="productflow">
        <div class="max_width_1200">
          <p class="productflow_title">
            {{ info.contents.lctitle }}
            <!-- 券品业务闭环管理业务流程 -->
          </p>
          <div class="productflow_img">
            <img :src="$commonJs.formatImgSrc(info.contents.liucheng)" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsInfo } from "@/network/public";

import Level2Banner from "@/components/level2Banner.vue";
export default {
  name: "SecurityProducts",
  components: {
    Level2Banner,
  },
  data() {
    return {
      info: {},
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      let queryParams = this.$route.query;
      if (queryParams.id) {
        this.getInfo(queryParams.id);
      } else {
        this.getInfo(1);
      }
    },
  },
  mounted() {
    let queryParams = this.$route.query;
    if (queryParams.id) {
      this.getInfo(queryParams.id);
    } else {
      this.getInfo(1);
    }
  },

  methods: {
    getInfo(id) {
      getGoodsInfo({
        id: id,
      })
        .then((res) => {
          if (res.status == 200) {
            this.info = res.data;
          }
        })
        .catch((e) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.products {
  padding: 88px 0 14px 0;
}
.productfunc {
  .productfunc_title {
    font-weight: 700;
    font-size: 30px;
    color: #222222;
    line-height: 30px;
    text-align: center;
  }
  .productfunc_content {
    margin-top: 45px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 19px;
    grid-row-gap: 24px;
    &_item {
      display: flex;
      align-items: flex-start;
      background: #ffffff;
      box-shadow: 4px 4px 15px 0px rgba(234, 234, 234, 0.7608);
      border-radius: 12px 12px 12px 12px;
      padding: 37px 52px;
      &_left {
        img {
          width: 90px;
          height: 90px;
          object-fit: contain;
        }
      }
      &_right {
        margin-left: 34px;
        display: flex;
        flex-direction: column;
        &_title {
          font-weight: 400;
          font-size: 22px;
          color: #222222;
          line-height: 22px;
        }
        &_desc {
          margin-top: 20px;
          // font-weight: 290;
          font-size: 16px;
          color: #666666;
          line-height: 22px;
          letter-spacing: 1px;
        }
      }
    }

    .productfunc_content_item:hover {
      .rotate_img {
        transform: rotateY(360deg);
        transition: all 1s ease-in-out;
      }
    }
  }
}

.productflow {
  margin-top: 80px;
  .productflow_title {
    font-weight: 400;
    font-size: 30px;
    color: #222222;
    line-height: 30px;
    text-align: center;
  }
  .productflow_img {
    margin-top: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 962px;
      // height: 457px;
      object-fit: contain;
    }
  }
}
</style>