<template>
  <div class="swiper-container">
    <swiper
      ref="certificateSwiper"
      class="certificate_swiper"
      :options="swiperOption"
    >
      <swiper-slide
        class="swiper-wrapper"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="certificate_item">
          <img :src="$commonJs.formatImgSrc(item.image)" alt="" />
          <span>{{ item.title }}</span>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { BASE_URL } from "@/config/index.js";
export default {
  name: "swiper-example-loop-group",
  title: "Loop mode with multiple slides per group",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  created() {},
  data() {
    return {
      BASE_URL: BASE_URL,
      certificateIndex: 0,
      swiperOption1: {
        autoplay: false, // 自动轮播
        speed: 600, // 轮播速度
        slidesPerView: 4,
        spaceBetween: 0,
        slidesPerGroup: 1,
        loop: false,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".certificate-next",
          prevEl: ".certificate-prev",
        },
        slideToClickedSlide: true,
      },
      swiperOption: {
        preventClicksPropagation: true,
        speed: 3000, //匀速时间
        freeMode: true,
        loop: true,
        slidesOffsetBefore: 0, //左边偏移量
        autoplay: {
          delay: 0, //停顿时间
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        slidesPerView: 4,
        spaceBetween: 10, //间隔
        loopFillGroupWithBlank: true,
        normalizeSlideIndex: true,
        allowTouchMove: true, //不允许触摸滑动
        centeredSlides: true, //当slide的总数小于slidesPerView时，slide居中
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".certificate-next",
          prevEl: ".certificate-prev",
        },
      },
    };
  },

  computed: {
    swiper() {
      return this.$refs.certificateSwiper.$swiper;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep .swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: linear;
  /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
  margin: 0 auto;
}

.certificate_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 90%;
    height: 163.44px;
    object-fit: contain;
  }
  span {
    font-weight: 400;
    font-size: 22px;
    color: #222222;
    line-height: 22px;
    margin-top: 30px;
  }
}
</style>