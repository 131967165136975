// let commonUrl = "https://quanming.craftsman-web.com.cn"
let commonUrl = "https://phwt.ekaquan.com"
let commonUrl2 = "https://www.ekaquan.com"
/**
 * 服务器路径 
 */
export const BASE_URL = commonUrl;
/**
 * 富文本前缀 
 */
export const RICH_URL = commonUrl;

/**
 * H5前缀 
 */
export const BASE_URL2 = commonUrl2;


