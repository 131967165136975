<template>
  <div>
    <!-- -->
    <div class="common_top"></div>
    <Level2Banner
      v-if="info.contents"
      :title="info.contents.title"
      :descList="info.contents.desc"
      :bannerImage="info.contents.image"
      :outerUrl="info.contents.url"
      :btnText="info.btn_text"
      :level2Title="info.contents.title_small"
    ></Level2Banner>
    <div class="news">
      <div class="max_width_1200">
        <div class="news_articles">
          <template v-for="(item, index) in newsList">
            <div
              @click="articleClick(item, 1)"
              :key="index"
              class="news_articles_item"
            >
              <div class="news_articles_item_top">
                <img :src="$commonJs.formatImgSrc(item.image)" alt="" />
              </div>
              <div class="news_articles_item_bot">
                <p class="news_articles_item_bot_title">{{ item.title }}</p>
                <div class="news_articles_item_bot_desc">
                  <div class="news_articles_item_bot_desc_before">
                    <span></span>
                  </div>
                  <p>
                    {{ item.desc }}
                  </p>
                </div>
                <div class="news_articles_item_bot_btn">
                  <button class="common_btn_style">查看详情</button>
                  <span>{{ item.time_create }}</span>
                  <!-- 2024年5月4日 -->
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="news_articles2">
          <p class="news_articles2_title">推荐阅读</p>
          <div class="news_articles2_list">
            <template v-for="(item, index) in recommedList">
              <div
                @click="articleClick(item, 2)"
                :key="index"
                class="news_articles2_item"
              >
                <div class="news_articles2_item_left">
                  <img :src="$commonJs.formatImgSrc(item.image)" alt="" />
                </div>
                <div class="news_articles2_item_right">
                  <p class="news_articles2_item_right_title">
                    {{ item.title }}
                  </p>
                  <div class="news_articles2_item_right_desc">
                    <p>发布时间：{{ item.time_create }}</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNews, getNewsDetail } from "@/network/public";
export default {
  name: "SecurityNews",

  data() {
    return {
      info: {},
      newsList: [],
      recommedList: [],
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      let queryParams = this.$route.query;
      if (queryParams.id) {
        this.getInfo(queryParams.id);
      } else {
        this.getInfo(1);
      }
    },
  },
  mounted() {
    let queryParams = this.$route.query;
    if (queryParams.id) {
      this.getInfo(queryParams.id);
    } else {
      this.getInfo(1);
    }
  },

  methods: {
    articleClick(item, hase) {
      if (hase == 2) {
        window.open(item.url, "_blank");
      } else if (hase == 1) {
        this.$router.push({
          path: "/newsDetail",
          query: {
            id: item.id,
          },
        });
      }
    },
    getInfo() {
      getNews()
        .then((res) => {
          if (res.status == 200) {
            this.info = res.data.top;
            this.newsList = res.data.list;
            this.recommedList = res.data.recommend;
          }
        })
        .catch((e) => {});
    },
  },
};
</script>
<style lang="scss">
.news {
  .news_articles {
    margin-top: 78px;
    padding-bottom: 120px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 39px;
    grid-column-gap: 21px;
    .news_articles_item {
      background: #ffffff;
      box-shadow: 0px 2px 13px 0px rgba(161, 161, 161, 0.2);
      border-radius: 12px 12px 12px 12px;
      padding: 16px;
      padding-bottom: calc(52px - 4px);
      &_top {
        background: #3f3f3f;
        border-radius: 12px 12px 12px 12px;
        overflow: hidden;
        width: 356px;
        height: 176px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s ease;
        }
      }
      &_bot {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        width: 356px;

        &_title {
          font-weight: 400;
          font-size: 22px;
          color: #222222;
          line-height: 22px;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          text-overflow: ellipsis;
        }
        &_desc {
          display: flex;
          align-items: flex-start;
          margin-top: 23px;

          .news_articles_item_bot_desc_before {
            display: flex;
            align-items: center;
            height: 24px;
            span {
              display: block;
              width: 7px;
              height: 7px;
              background: #006c50;
              border-radius: 50%;
            }
          }
          p {
            margin-left: 10px;
            font-weight: 400;
            font-size: 18px;
            color: #6b6866;
            line-height: 24px;
          }
        }
        .news_articles_item_bot_btn {
          margin-top: 33px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            width: 142px;
            height: 42px;
            background: #006c50;
            border-radius: 21px 21px 21px 21px;
            border-width: 4px;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 14px;
          }
          span {
            // font-weight: 290;
            font-size: 14px;
            color: #8d9296;
            line-height: 14px;
          }
        }
      }
    }
    .news_articles_item:hover {
      .news_articles_item_top {
        img {
          transform: scale(1.1);
        }
      }
      .news_articles_item_bot {
        .news_articles_item_bot_title {
          color: rgba(0, 108, 80, 1);
        }
      }
    }
  }
  .news_articles2 {
    .news_articles2_title {
      font-weight: 400;
      font-size: 30px;
      color: #222222;
      line-height: 30px;
      text-align: center;
    }
    .news_articles2_list {
      margin-top: 36px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
      padding-bottom: 121px;
      .news_articles2_item {
        background: #ffffff;
        box-shadow: 0px 3px 16px 0px rgba(161, 161, 161, 0.2);
        border-radius: 12px 12px 12px 12px;
        padding: 16px;
        display: flex;
        align-items: center;
        .news_articles2_item_left {
          width: 176px;
          height: 176px;
          background: #3f3f3f;
          border-radius: 12px 12px 12px 12px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.5s ease;
          }
        }
        .news_articles2_item_right {
          margin-left: 29px;
          //   align-self: stretch;
          &_title {
            font-weight: 400;
            font-size: 22px;
            line-height: 22px;
          }
          &_desc {
            // font-weight: 290;
            font-size: 14px;
            color: #8d9296;
            line-height: 14px;
            margin-top: 54px;
          }
        }
      }
      .news_articles2_item:hover {
        .news_articles2_item_left {
          img {
            transform: scale(1.1);
          }
        }
        .news_articles2_item_right {
          &_title {
            color: #006c50;
          }
        }
      }
    }
  }
}
</style>