<template>
  <div>
    <!-- -->
    <div class="common_top"></div>
    <Level2Banner
      v-if="info.contents"
      :title="info.contents.title"
      :bannerImage="info.contents.image"
      :outerUrl="info.contents.url"
      :btnText="info.btn_text"
      :level2Title="info.contents.title_small"
      :descList="info.contents.desc"
    ></Level2Banner>
    <div class="customerscontent">
      <div id="listTop" class="max_width_1200">
        <div class="customers_tabs">
          <div class="customers_tabs_row">
            <div class="customers_tabs_row_left">
              <span>使用场景</span>
            </div>
            <div class="customers_tabs_row_right">
              <template v-for="(item, index) in allInfo.scene">
                <div
                  @click="sceneClick(item, index)"
                  :key="index"
                  class="customers_tabs_row_right_item"
                  :class="
                    index == sceneIndex
                      ? 'customers_tabs_row_right_item_active'
                      : ''
                  "
                >
                  <span>{{ item.name }}</span>
                </div>
              </template>
            </div>
          </div>
          <div class="customers_tabs_row">
            <div class="customers_tabs_row_left">
              <span>应用集团</span>
            </div>
            <div class="customers_tabs_row_right">
              <template v-for="(item, index) in allInfo.group">
                <div
                  @click="groupClick(item, index)"
                  :key="index"
                  class="customers_tabs_row_right_item"
                  :class="
                    index == groupIndex
                      ? 'customers_tabs_row_right_item_active'
                      : ''
                  "
                >
                  <span>{{ item.name }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="customers_cards">
          <template v-for="(item, index) in list">
            <div
              @click="listTap(item, index)"
              :key="index"
              class="customers_card"
            >
              <div class="customers_card_top">
                <img :src="$commonJs.formatImgSrc(item.image)" alt="" />
              </div>
              <div class="customers_card_bot">
                <span class="customers_card_bot_title">{{ item.name }}</span>
                <img
                  class="customers_card_bot_logo"
                  :src="$commonJs.formatImgSrc(item.logo)"
                  alt=""
                />
                <div class="customers_card_bot_tags">
                  <div
                    v-for="(childItem, childIndex) in item.scene"
                    :key="childIndex"
                    class="customers_card_bot_tags_item"
                  >
                    <img src="../assets/img/lvduihao.png" alt="" />
                    <span>{{ childItem }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-if="pageTotal > 0" class="pagination">
          <!-- <el-pagination
            color="red"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryForm.page"
            :page-sizes="[12, 24]"
            :page-size="queryForm.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal"
          >
          </el-pagination> -->
          <button
            class="next_btn common_btn_style"
            v-if="queryForm.page != 1"
            @click="handleCurrentChange(queryForm.page - 1)"
          >
            上一页
          </button>
          <span
            v-if="queryForm.page != 1 && queryForm.page != maxPage"
            style="display: block; width: 20px"
          ></span>
          <button
            v-if="queryForm.page == 1 || queryForm.page != maxPage"
            class="next_btn common_btn_style"
            @click="handleCurrentChange(queryForm.page + 1)"
          >
            下一页
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCaseInfo, getCaseList } from "@/network/public";

export default {
  name: "SecurityCustomers",

  data() {
    return {
      allInfo: {},
      info: {},
      list: [],
      sceneIndex: 0,
      groupIndex: 0,
      queryForm: {
        scene: "",
        brank: "",
        limit: 15,
        page: 1,
      },
      pageTotal: 0,
      maxPage: 1,
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      this.getInfo();
    },
  },
  async mounted() {
    this.getInfo();
    // this.getList();
  },

  methods: {
    async handleSizeChange(e) {
      console.log(e);
      this.queryForm.page = 1;
      this.queryForm.limit = e;
      await this.getList();
      document.querySelector(`#listTop`).scrollIntoView({
        behavior: "smooth",
      });
    },
    async handleCurrentChange(e) {
      console.log(e);
      if (e > this.maxPage) {
        this.$message.info("已经是最后一页");
        return false;
      }
      this.queryForm.page = e;

      await this.getList();
      setTimeout(() => {
        document.querySelector(`#listTop`).scrollIntoView({
          behavior: "smooth",
        });
      }, 300);
    },
    getIndex(id) {
      this.allInfo.group.forEach((item, index) => {
        if (item.id == id) {
          this.groupIndex = index;
          this.groupClick(item, index);
          return;
        }
      });
    },
    listTap(item, index) {
      // 类型 1 文章 2 公众号链接跳转
      if (item.type == 2) {
        if (item.url) {
          window.open(item.url, "_blank");
        }
      } else if (item.type == 1) {
        // this.$router.push({
        //   path: "/caseDetail",
        //   query: {
        //     id: item.id,
        //     from: "group",
        //   },
        // });
        let routerJump = this.$router.resolve({
          path: "/caseDetail",
          query: {
            id: item.id,
            from: "group",
          },
        });
        window.open(routerJump.href, "_blank");
      }
    },
    sceneClick(item, index) {
      this.queryForm.page = 1;
      this.sceneIndex = index;
      if (item.id != -1) {
        this.queryForm.scene = item.id;
      } else {
        delete this.queryForm.scene;
      }
      this.getList();
    },
    groupClick(item, index) {
      this.queryForm.page = 1;
      this.groupIndex = index;
      if (item.id != -1) {
        this.queryForm.brank = item.id;
      } else {
        delete this.queryForm.brank;
      }
      this.getList();
    },
    getInfo() {
      getCaseInfo()
        .then((res) => {
          if (res.status == 200) {
            this.info = res.data.top;
            this.allInfo = res.data;
            this.allInfo.scene.unshift({
              name: "全部",
              id: -1,
            });
            this.allInfo.group.unshift({
              name: "全部",
              id: -1,
            });
            let queryParams = this.$route.query;
            if (queryParams.id) {
              this.getIndex(queryParams.id);
            } else {
              this.groupIndex = 0;
              delete this.queryForm.brank;
              this.getList();
            }
          }
        })
        .catch((e) => {});
    },
    getList() {
      getCaseList(this.queryForm)
        .then((res) => {
          if (res.status == 200) {
            this.list = res.data.list;
            this.pageTotal = res.data.count;
            this.maxPage = Math.ceil(this.pageTotal / this.queryForm.limit);
          }
        })
        .catch((e) => {});
    },
  },
};
</script>
<style lang="scss" >
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 98px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  .el-pager li.active {
    color: #006c50;
  }
  .next_btn {
    background: #006c50;
    border-radius: 50px;
    border-width: 6px;
    padding: 10px 17px;
    font-weight: 400;
    min-width: 221px;
    white-space: nowrap;
    font-size: 18px;
    color: #ffffff;
    line-height: 30px;
  }
}
.el-select-dropdown__item.selected {
  color: #006c50 !important;
}
.el-pagination .el-select .el-input .el-input__inner {
  border-color: #006c50 !important;
  input.is-focus .el-input__inner {
    border-color: #006c50 !important;
  }
}

.customerscontent {
  position: relative;
  top: calc(105px - 168px);
  z-index: 1;

  .customers_tabs {
    background: #ffffff;
    box-shadow: 4px 9px 17px 0px rgba(152, 152, 152, 0.3608);
    border-radius: 8px 8px 8px 8px;
    padding: 26px 55px 31px 56px;
    // position: sticky;
    // top: 166px;

    /* 滚动条样式优化 */
    // ::-webkit-scrollbar {
    //   width: 0px;
    //   height: 0px;
    //   background: #cccccc;
    //   border-radius: 4px;
    // }

    .customers_tabs_row {
      display: flex;
      align-items: flex-start;
      span {
        font-size: 16px;
        color: #222222;
        line-height: 18px;
        white-space: nowrap;
        padding: 0 2px;
        font-weight: 400;
      }

      .customers_tabs_row_left {
        span {
          line-height: 38px;
        }
      }
      .customers_tabs_row_right {
        margin-left: 37px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;

        .customers_tabs_row_right_item {
          padding: 10px 27px;
          border-radius: 8px 8px 8px 8px;
          cursor: pointer;
          padding: 10px 10px;
          min-width: 90px;
          text-align: center;
          margin-right: 6px;
          // width: 93px;
        }
        .customers_tabs_row_right_item:last-child {
          margin-right: 0;
        }
        .customers_tabs_row_right_item_active {
          background: rgba(0, 108, 80, 0.1);

          span {
            font-weight: 700;
            font-size: 18px;
            color: #006c50;
            line-height: 18px;
            padding: 0;
          }
        }
      }
    }
    .customers_tabs_row:last-child {
      margin-top: 35px;
    }
  }
  .customers_cards {
    margin-top: 49px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    grid-row-gap: 83px;
    // max-height: 50vh;
    // overflow-y: scroll;
    .customers_card {
      background: #ffffff;
      box-shadow: 0px 2px 13px 0px rgba(161, 161, 161, 0.1608);
      border-radius: 18px 18px 18px 18px;
      overflow: hidden;
      position: relative;
      top: 0;
      transition: all 0.3s ease-out;
      cursor: pointer;
      &:hover {
        top: -20px;
        box-shadow: 0 8px 12px 1px rgba(0, 0, 0, 0.05);
      }
      .customers_card_top {
        border-radius: 18px 18px 0 0;
        overflow: hidden;
        img {
          width: 100%;
          height: 279.5px;
        }
      }
      .customers_card_bot {
        padding: 49px 35px;
        padding-bottom: 23px;
        display: flex;
        flex-direction: column;
        &_title {
          font-weight: 700;
          font-size: 25px;
          color: #222222;
          line-height: 25px;
        }
        &_logo {
          width: 127.81px;
          height: 127.81px;
          object-fit: contain;
        }
        &_tags {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 5px;
          &_item {
            display: flex;
            align-items: center;
            margin-right: 26px;
            margin-bottom: 12px;
            img {
              width: 13.5px;
              height: 13.5px;
            }
            span {
              // font-weight: 290;
              font-size: 14px;
              color: #222222;
              line-height: 14px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
</style>