import Vue from "vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Level2Banner from "@/components/level2Banner.vue";
import FormComponents from "@/components/formComponents.vue";
import RichEditer from "@/components/RichEditer.vue";
import RightFix from "@/components/rightFix.vue";
import Identify from "@/components/Identify.vue";



Vue.component("Swiper", Swiper);
Vue.component("SwiperSlide", SwiperSlide);
Vue.component("Level2Banner", Level2Banner);
Vue.component("FormComponents", FormComponents);
Vue.component("RichEditer", RichEditer);
Vue.component("RightFix", RightFix);
Vue.component("Identify", Identify);









