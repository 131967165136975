<template>
  <div class="notFoundContent max-width-1400">
    <div class="imgBox">
      <img src="@/assets/img/404.png" />
    </div>
    <div class="notFoundRight">
      <p class="number">404</p>
      <p class="text">很抱歉，您要访问的页面地址有误， 或者该页面不存在。</p>
      <div @click="toHome" class="btnStyle">返回首页</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "XinhaiSoftwareFrontEnd404",

  data() {
    return {};
  },

  mounted() {},

  methods: {
    toHome() {
      this.$commonJs.changeView("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.notFoundContent {
  padding: 80px 20px;
  padding-bottom: 0;
  min-height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .imgBox {
    img {
      width: 500px;
      height: 500px;
    }
  }
  .notFoundRight {
    margin-left: 35px;
    display: flex;
    flex-direction: column;

    .number {
      font-size: 80px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 112px;
    }

    .text {
      font-size: 22px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #3d3d3d;
      line-height: 40px;
      max-width: 352px;
    }

    .btnStyle {
      cursor: pointer;
      width: 98px;
      display: flex;
      background: #006c50;

      border-radius: 7px 7px 7px 7px;
      padding: 7px 21px;

      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      margin-top: 18px;
    }
  }
}
@media only screen and (max-width: 900px) {
  img {
    max-width: 90vw;
    overflow: hidden;
  }
  .notFoundContent {
    flex-direction: column;
    padding-bottom: 30px;
    .imgBox {
      img {
        width: 200px;
        height: 200px;
      }
    }
    .notFoundRight {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      text {
        text-align: center;
      }
    }
  }
}
</style>